/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import './videocontainer.scss'
import './videocontrols.scss'
import OverLayRender from './OverLayRender';
import './overlaypropsetter.scss'
import CuePoints from "./CuePoints";
import { pathCreater ,TEMP_SUBTITLE_PATH} from "../../../utils/MediaConfig";
import PropTypes from 'prop-types'
import PlayerConstants from '../../../utils/PlayerConstants';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import hotkeys from "hotkeys-js";
import VideoContentText from "./VideoContentText";
import Instruction from "../../ui/Instruction/Instruction";
import CuePoint from "../../../utils/Cuepoint";
import DataHelper from "../../../utils/DataHelper";
import ComponentConstants from '../../../utils/ComponentConstants';
import BuildHelper from "../../../utils/BuildHelper";

/**
 * 
 * @param {*} src 
 * @returns video source url of the component
 */

/**
 * 
 * @param {*} cuepoints 
 * @returns cuepoints array for the video for the displaying message
 */

/**
 * 
 * @param {*} color 
 * @returns display colors of the cuepoints
 */

/**
 * 
 * @param {*} poster 
 * @returns Url for background overlay image
 */

/**
 * 
 * @param {*} readable 
 * @returns innerhtml string for rendering some content
 */

/**
 * Checks if the video has interactive hotspots and manages the 
 * video hotspot learning aid activity based on the `hasHotspot` flag.
 *
 * If `hasHotspot` is true, it passes the necessary data to the 
 * video hotspot learning aid using `handleHotspot`.
 * 
 * @param {boolean} hasHotspot - Indicates whether the videohotspots.
 * @param {function} handleHotspot - A function that processes and passes data to the videohotspots learning aid.
 */


/**
 * video player interactivity content component can be customized using below props 
*/
const Video = ({ src, cuepoints, poster, readable, track, handler, cename, instructions, description, content, topic_id, userInfo, forward_rewind, language="English", hasCue, handleHotspot, hasHotspot}) => {
    const videoPlayerRef = useRef(null);

    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

    const [playerReady, setPlayerReady] = useState(false);
    const [isOverLay, setOverLay] = useState(false);
    const [contentText, setContentText] = useState(content)
    const [duration, setDuration] = useState(0)
    const [status, setStatus] = useState({completed: false, text: 'Pending'})
    const [isDragEnabled, setDragEnabled] = useState(track?.status === true ? true:false)
    const [instruction, setInstruct] = useState(instructions)
    const [showTextVersion, setTextVersion] = useState(false)
    const [showCCTextVersion, setCCTextVersion] = useState(false)
    const [subtitle, setSubtitle] = useState(null)
    const [currentCue,setCurrentCue] = useState({})
    const [videoJSPlayer,setVideoJSPlayer] = useState(null);
    const [controlArea, setControlArea ] = useState(null);
    const [subtitlePath, setSubtitlePath] = useState('');
    const colors=[
        {id: 1,color: "#ff9900",},
        {id: 2,color: "#F3C465",},
        {id: 3,color: "#3381a4",},
        {id: 4,color: "#ffffff",},
    ];
    const videoAsp = () => {
        let contentAreaWidth = document.getElementsByClassName( 'content' )[ 0 ]?.clientWidth;
        return DataHelper.getVideoAspectRatio( contentAreaWidth )
    }
    const [videAsp,setVideoAsp] = useState(videoAsp);

    useLayoutEffect(() => {
        function updateSize() {
            setVideoAsp(videoAsp);
            //console.debug("videAsp",videAsp)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);


    if(track === undefined || handler === undefined) {
        track = {}
        handler = () => {

        }
    }
    // console.log('track video', track, handler)


    const enablehotKeys = () => {
        hotkeys('ctrl+alt+7, ctrl+alt+d', function (event, handler) {
            switch (handler.key) {
                case 'ctrl+alt+7':
                    setDragEnabled(true)
                    console.log('enabled video seek', isDragEnabled)
                    break;
                case 'ctrl+alt+d':
                    setDragEnabled(false)
                    console.log('disabled video seek', isDragEnabled)
                    break;
              default: return 1;
            }
          });
    }

    let videoJSOptions = {
        playbackRates: [1],
        controls: true,
        autoplay: false,
        muted: false,
        userActions: { hotkeys: true },
        controlBar: {
            volumePanel: true,
            pictureInPictureToggle: true
        }
    };

    if(!["","*"].includes(poster)){
        videoJSOptions = {
           ...videoJSOptions,
           poster: DataHelper.getResourcePath(0,poster)
       }
    }
    
    const [overlayColor, setOverlayColor] = useState(colors?.length > 0 ? colors[0]?.color: []);

    /**
    * Manage the component update 
    * progress logic in this method
    * 
    * Update the view status when ever the user interacts
    * Update the progess status ONLY ONCE, when status completes
    * 
    */
    const updateProgress = (currentItem) => {
       // console.log('track is', track)
        switch (currentItem?.type) {
            case 'END':
                if (track?.status === 0) {
                   // console.log('track video', track)
                    track.status = 1
                    setInstruct({
                        ...instruction,
                        text: `You have completed this interactivity`,
                        className: 'completed'
                    })
                    setStatus({completed: true, text:PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE})
                    handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename });
                }
                break;
            case 'PLAYHEAD':
                if(!track.status) {
                    track.status = 0
                } else if(!track.state) {
                    track.state = { ...track.state, t: currentItem.time }
                }
                handler({ type: UPDATE_STATUS, id: track?.id, name: cename })
                break;
        }
    }
    const enableCuePoint=(time)=>{
        for (var i in cuepoints) {
            var t = (Math.floor(time));
            if (Math.floor(Number(cuepoints[i].point)) < t) {
                cuepoints[i].shown = false;
            }
        }
    }
    const closeOverLay = () => {
        setOverLay(false);
        videoPlayerRef.current.play();
    }

    const showCuePoints = (cue) => {
        // cue.shown = true;
        // setCurrentCue(cue)
        if (cuepoints[cue].ctype != 5) {
            cuepoints[cue].shown = true;
            setCurrentCue(cuepoints[cue])
            /* Need to show content here */
            setOverLay(true)

            videoPlayerRef.current.pause();
            setOverlayColor([getColorByType(cuepoints[cue].ctype)])
        }

    }

    const getColorByType = (type) => {
        let colors = {
            0: { c: '#ffffff' },
            1: { c: '#ffffff' },
            2: { c: '#F3C465' },
            3: { c: '#81b84d' },
            4: { c: '#81b84d' },
            5: { c: '#81b84d' }
        }
        return colors[type].c
    }
    
    const renderCuePoints = (time) => {
        const tolerance = 0.12; // Adjust this value as needed
        for (const i in cuepoints) {
            const cuePointTime = Number(cuepoints[i].point);
            const isWithinTolerance = (Math.abs(cuePointTime - Number(time)) <= tolerance);
            if (isWithinTolerance ) {
                showCuePoints(i);
            }
        }
    };

    const getResourceUrl = (path)=>{
        return DataHelper.getResourcePath(1,path)
    }

    /** check the url is absolute or only video name is given */
    const getAbsUrl = (src)=>{
        try{
            if(src)
            return src.startsWith('http') ? src:getResourceUrl(src)
        }catch(e){
            console.log(e)
        }
    }


   

    const getVideoUrl = ()=>{
    try{
        let selected = {url:getAbsUrl(src),type:'video/mp4'};
        let ar = src.split('.');
        let ext = ar[ar?.length-1];
        //  Using string manipulation with lastIndexOf
        const filename = selected?.url?.substring(selected?.url?.lastIndexOf("/") + 1, selected?.url?.lastIndexOf("."));
        // Check if the extension is "m3u8" or if the selected includes "manager" or "assetslib"
        if (ext === "m3u8" || selected?.url?.includes("manager") || selected?.url?.includes("assetslib")) {
            // Construct the path for the m3u8 file
            let path = `${PlayerConstants?.VIDEO_M3U8_PATH}${filename}/hls/stream.m3u8`;
            // Update the selected URL and type accordingly
            selected.url = ext === "m3u8" ? getResourceUrl(src) :path;
            selected.type = 'application/x-mpegURL';
        }
        return selected;
    }catch(e){
        return {url:getAbsUrl(src),type:'video/mp4'};
    }
    }


    // const tryRequire = (path) => {
    //     console.log('file exists', fs.existsSync(path))
    //     if (fs.existsSync(path)) {
    //           //file exists
    //           return require(path).default
    //     } else {
    //         return require('../../../assets/data/subtitle/subtitle_eng.vtt').default
    //     }
    //   };

    /**
     * Fetches multi-content data from the API.
     * 
     * - The function constructs the API endpoint dynamically based on `PlayerConstants`.
     * - It sends a GET request to the server.
     * - If the response is successful, it attempts to parse the response as JSON.
     * - If the response is not successful or the response format is invalid, an error is handled.
     * - The `content` state is updated with the data, or a fallback value is returned in case of failure.
     *
     * The parsed JSON response or fallback content in case of failure.
     */
    const getMulticontent = async () => {
        try {
            let ar = src.split('/');
            let file = ar[ar?.length-1];
            let assetsId = file?.split('.');
            
            const path = `${PlayerConstants?.API_DESTINATION_STAGING || ''}assets/getmulticontent/${assetsId[0]}`;
            const payload = {method: "GET"};
            const response = await fetch(path, payload);
            const res = await response?.data;
            if (!res || typeof res.data !== 'object'|| response?.status !=="success" ) {
                return content
            }
            setContentText(res?.data[BuildHelper.defaultLanguage()])
            return res;
        } catch (error) {
            console.error("Error ", error.message || error);
            return content;
        }
    };

    const cueRender=(playerEle)=>{
        const progressControl = playerEle.controlBar.progressControl.seekBar.el();
        const customDiv = document.createElement("div");
        customDiv.className = "custom-progress-div";
        const customProgressDiv = document.querySelector(".custom-progress-div");
        //if the element exists before attempting to removing it
        if (customProgressDiv) {customProgressDiv.remove();}
        setControlArea({parentEel:progressControl,cueEle:customDiv});
    }
    let errorHandled = false;
    useEffect(() => {
    try{
        let hasLoadedMetadata=false
        if (videoPlayerRef) {
           // console.log('videoplayeref', videoPlayerRef)    
            let videoInfo = getVideoUrl();
            let player = videojs(videoPlayerRef.current, videoJSOptions, () => {
                let currentTime = 0
                player.src({
                   src: `${videoInfo?.url}#t=0.5`,
                   type: videoInfo?.type ?? 'video/mp4',
                });
                player.on('loadedmetadata', () => {
                    if(hasHotspot){
                        handleHotspot({type:'1',data:player})
                        return;
                      }
                        getMulticontent(); // Call only on first loadedmetadata event
                    })
                player.on('loadstart', () => { });
                player.on('play', (e) => {
                    setPlayerReady(true);
                    addCuePoint();
                });
                player.on('playing', (e) => {
                    setDuration(Math.floor(player?.duration()))
                    const mediaEle = document.querySelectorAll('video, audio')
                    for (const media of mediaEle) {
                        if (media !== e.target.children[0]) {
                            media.pause()
                        }
                    }
                })
                player.on("ended", () => {
                    if (player.isFullscreen()) {
                        setTimeout(() => {
                          player.exitFullscreen(); 
                        }, 100); // Wait for the duration
                    }
                    if(hasHotspot){
                        handleHotspot({type:'2',data:player})
                        return;
                    }
                    setDragEnabled(true)
                    updateProgress({ type: 'END' });
                    for (const i of cuepoints) {
                        i.shown = false
                    }
                });
                player.on('error', (e) => {
                    if (!errorHandled) {
                      player.src(getAbsUrl(src));
                      player.load();
                      // player.play();
                      errorHandled = true;
                    }
                  });
                player.on("timeupdate", () => {

                    if(hasHotspot){
                        handleHotspot({type:'3',data:{t:player?.currentTime(),p:player}})
                        return;
                      }
                    if (!BuildHelper.isOnScorm()){
                      updateProgress({ type: 'PLAYHEAD', time: player.currentTime() })
                    }
                    enableCuePoint(player?.currentTime());
                    // dispatc
                    if (cuepoints?.length > 0) {
                        try{
                            renderCuePoints(player.currentTime())
                        }catch(e){
                            console.debug(e);
                        }
                    }
                });
            });
            cueRender(player);
            setVideoJSPlayer(player);

        }
    }catch(e){ console.log(e);}
    }, []);


    useEffect(()=>{ 
        try{ cueRender(videoJSPlayer);}catch(e){} 
    },[cuepoints])


    useEffect(() => {
        enablehotKeys()
        let controlBar = document.querySelectorAll('.vjs-progress-control')
        if(isDragEnabled) {
            controlBar.forEach(el => el.style.pointerEvents = 'initial')
        } else {
            controlBar.forEach(el => el.style.pointerEvents = 'none')
        }
    }, [isDragEnabled])

    const handleTextVersion = () => {
        setTextVersion(true)
        videoPlayerRef.current.pause();
    }

    const closeTextOverlay = () => {
        setTextVersion(false)
        videoPlayerRef.current.play();
    }

    const getCueList = (list) =>{
        try{
            const mapped = list?.map((el)=>{
                return new CuePoint(el)
            });
            return mapped;
        }catch(e){}
    }

    const getFileName = ( src ) => {
        let srcUrl = src.split( '-' )
        let filename = srcUrl[ srcUrl?.length - 1 ].slice( 0, -4 )
        return filename
    }
    /**
     * Asynchronously checks if the subtitle file exists at the given URL.
     *
     *  The URL to check for the subtitle file.
     *  Returns the response data if the file exists, or false if an error occurs.
     */
    const checkSubtitleExists = async (url) => {
        try {
            const payload = {method: "GET"};
            const response = await fetch(url, payload);
            if (response || response?.data||response?.status ==="success"){  
              return false; // Subtitle file exists
            } else {
                    return false; // Subtitle file doesn't exist
                }
        } catch (error) {
            console.error('Error checking subtitle URL:', error);
            return false; // Return false if there is an error
        }
    };
    
    /**
     * Retrieves the path for the subtitle file based on the provided language code.
     * 
     * If the provided language is not available, it falls back to English ('en_US').
     * The function constructs the correct path for the subtitle file based on 
     * whether the player is in a SCORM environment or not.
     * 
     * @param {string} lanCode - The language code to generate the subtitle path (e.g., 'en_US', 'fr_FR').
     * @returns {string} - The path to the subtitle file or the fallback subtitle path.
     */
    const getSubtitlePath = (lanCode) => {
        let lan = '';
        const defaultLang =lanCode;
        const hasLanguage=defaultLang && defaultLang !== 'en_US'

        // Ensure the language is not 'en_US' and not null or empty only we adding _lanCode
        if (hasLanguage) {
            lan = `_${defaultLang}`;
        }
        
       let test = require(`../../../assets/data/subtitle/subtitle_eng.vtt`).default;
        try {
            // https://assets.contentenablers.com/rlm/topics/616d1ae6424d275113d542bb/TCO_UK_FI_Intro.vtt
            // require(`../../../assets/data/subtitle/subtitle_eng.vtt`).default
            // let formatted = getFileName( src ).replace( '../', '' )
            // let path = `${ ASSET_PATH }${ formatted }.vtt`
            let ar = src.split('/');
            let file = ar[ar?.length-1];
            let fn = file.split('.');
            // let path = window.location.href.includes("alpha.rlm.contentenablers.com") ? `${TEMP_SUBTITLE_PATH}${fn[0]}.vtt` : test
            let path = PlayerConstants.CC_TXT_PATH + '1000/subtitlevtt/' + fn[0] + `${lan}.vtt`;
            if (BuildHelper.isOnScorm()){
                path = BuildHelper.getExternalPath()  + `./content/${BuildHelper?.defaultLanguage()||'en_US'}/subtitlevtt/${fn[0]}${lan}.vtt`;
            }

            // If the subtitle does not exist and a specific language was requested, fall back to default
            if(!checkSubtitleExists(test) && hasLanguage){
                return getSubtitlePath(null);
            }
            
            return path;
    
        } catch ( err ) {
            console.log( "err", err );
            setCCTextVersion(false);
            return getSubtitlePath(null) || test;
        }
    
    }


    const timeSeek = ( secs ) => {
        let time = videoJSPlayer.currentTime() + secs;
        if ( time < 0 ) {
            time = 0;
        }
        videoJSPlayer.currentTime( time );
        //console.debug("player",videoJSPlayer)
    }

    const forward = () => {
        timeSeek( 10 );
    }

    const rewind = () => {
        timeSeek( -10 );
    }

    useEffect(()=>{
        if(videoJSPlayer !== null){
            videoJSPlayer.src({
                src:getAbsUrl(src),
                type:'video/mp4'
             });
        }
    },[src])


    // useEffect(()=>{
    //     if(videoJSPlayer !== null){
    //             console.log(videoJSPlayer,"poster",pathCreater(0) + poster)
    //     }
    // },[poster]);

  
   
/* Cue Point add & edit */
const editPanelOpen = (edit) => {
    if(!BuildHelper.isLearner() && hasCue){
        let editItem = edit
        if (edit !== -1) {editItem = { ...edit }; videoJSPlayer.currentTime(edit?.time)};
        let curentTime=(Math.round(videoJSPlayer.currentTime() * 100) / 100).toFixed(2);
        if(hasHotspot){
            handleHotspot({type: ComponentConstants?.SAVE_COMP_PROPS ,data: { cename, topic_id, cduration:curentTime, isEdit: editItem }})
            return;
        }
        handler({ type: ComponentConstants?.SAVE_COMP_PROPS, data: { cename, topic_id, cduration:curentTime, isEdit: editItem } })
        if (edit === -1) setCurrentCue(cuepoints?.length - 1);
    }
}
/**
 * Adds a Cue Point Button to the video player progress bar.
 * This button allows users to add cue points during video.
 * Function only works for non-learner users.
 */
const addCuePoint=()=>{
    if(!BuildHelper.isLearner() && hasCue){
        setDragEnabled(true);
        let isCheck = document.querySelectorAll('.vjs-playing .vjs-play-progress .add-video-sub');
        if (playerReady && !isCheck[0]) {
            let playProgress = document.querySelectorAll('.vjs-playing .vjs-play-progress');
            let html = `+<br><span class="current-time">${formatTime(videoPlayerRef.current.currentTime)}</span>`; // Display current time
            let div = document.createElement('div');
            for (var i = 0; i < playProgress.length; i++) {
                playProgress[i].appendChild(div);
            }
            div.addEventListener('click', function (event) {
                videoPlayerRef.current.pause();
                editPanelOpen(-1);
                event.stopPropagation();
                event.preventDefault();
            });
            div.innerHTML = html;
            div.classList.add("add-video-sub");
            let toolName=hasHotspot ? 'Add  Hotspot' : 'Add  Cuepoints';
            div.setAttribute("data-gloss", toolName);
            setInterval(() => {
                const currentTimeSpan = div.querySelector('span');
                if (currentTimeSpan) {
                    currentTimeSpan.textContent = formatTime(videoPlayerRef.current.currentTime);
                }
            }, 1000);
        }
    }
}
function formatTime(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Math.floor(seconds % 60);
    return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
}
function padZero(num) {
    return (num < 10 ? '0' : '') + num;
}
const isCueCheck = (cue) => {
    try {
        for (var i in cuepoints) {
            if (cuepoints[i]?.point === cue?.point) {
                return true
            }
        }
        videoPlayerRef.current.play();
        return false;
    } catch (e) { }
}

/**
 const getSrcLan = React.useMemo(() => {
    try {
        let lan = BuildHelper?.defaultLanguage() || 'en_US';
        return lan?.split('_')[0] || 'en';
    } catch (e) {
        console.error(e);
        return 'en'; 
    }
}, [BuildHelper?.defaultLanguage()]);
*/


    // Use useEffect to only update subtitlePath when language changes
    useEffect(() => {
        const path = getSubtitlePath(BuildHelper.defaultLanguage());
        setSubtitlePath(path);
    }, [getSubtitlePath,src]);


    return (
        <>
        <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
        <div className="video-container"  onMouseOver={addCuePoint}>
                <video
                    oncontextmenu="return false;"
                    ref={videoPlayerRef}
                    className="video-js"
                    data-setup='{"fluid": true}'
                >
                    <source src="https://vjs.zencdn.net/v/oceans.mp4" type='video/mp4' />
                    <track kind='captions' src={subtitlePath} srcLang={'en_US'} label={"English"} default={false}  mode={showCCTextVersion ? 'showing' : 'hidden'} />
                </video>

                {content && <div className='vjs-video-text-version' onClick={handleTextVersion} title="Text-version">
                    <SubtitlesIcon className='video-text-icon'/>
                </div>}
            {/* For newly added video there wont be any cue point and in edit panel we dont have an option to add cuepoint 25/12/22*/}
            {playerReady && cuepoints?.length !== 0 && <CuePoints data={getCueList(cuepoints)} duration={duration}  onClick={(i) => editPanelOpen(i)} parent={controlArea} />}
            {isOverLay && isCueCheck(currentCue) &&<OverLayRender type={currentCue.ctype} data={currentCue} bgcolor={overlayColor} close={() => closeOverLay()}  userInfo={userInfo} />}
            {showTextVersion && <VideoContentText contentText={contentText} close={() => closeTextOverlay()}/> }
           {forward_rewind && <div className="forward-backward-btns">
                    <img alt='forward-icon' className="forward" src={require( `../../../assets/img/forward-ten_1.png` ).default} onClick={ () => forward()} />
                    <img alt='backward-icon' className="backward" src={require( `../../../assets/img/back-ten_1.png` ).default} onClick={ () => rewind()} />
            </div>}
        </div>
        {(readable || description) && <div className='readable-text text-content' dangerouslySetInnerHTML={{ __html: description ? description : readable}}></div> }
        </>
    );
};


Video.defaultProps = {
    instructions: {
        text: 'Please view the video to proceed.',
        enabled: false
    }
}

Video.propTypes = {
    /** Video source url of the component */
    src: PropTypes.string.isRequired,
    /** Poster Url for background overlay image */
    poster: PropTypes.string,
    /** Innerhtml string for rendering some content* */
    readable: PropTypes.string,
    /** Cuepoints array for the video for the displaying message */
    cuepoints: PropTypes.array,
    /** Display colors of the cuepoints overlay*/
    colors: PropTypes.array,
    /** Tracking the component progress */
    track: PropTypes.object,
    /** Func description for tracking*/
    handler: PropTypes.func,
    /** Cename describes name of component*/
    cename: PropTypes.string

};

export default Video