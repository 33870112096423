import { put, takeLatest, all, takeEvery } from "redux-saga/effects";
import * as types from "../reducers/types";
import * as courseInfoAction from "../actions/courseInfoAction";
import * as courseTrackingAction from "../actions/courseTrackingAction";
import PlayerConstants from "../../utils/PlayerConstants";
// import PlayerConstants from '../../utils/PlayerConstants'
import * as courseEditAction from "../actions/courseEditAction";
import * as utilityAction from "../actions/utilityAction";
import * as userInfoAction from "../actions/userInfoAction";
import * as ceAssetsInfoAction from "../actions/ceAssetsInfoAction";
import * as globalStyleAction from "../actions/globalStyleAction";
import DataHelper from "../../utils/DataHelper";
import BuildHelper from "../../utils/BuildHelper";
// import testStructure from '../../testStructure.json';
// import testStructure_auth from '../../testStructure_auth.json';

function* fetchProgress() {
  try{
    const roleId = Number(window.ce?.ceuser?.role_id) || 4;
      /**
     * Getting Progress
     * For Platform the api 'playerv2/auth' will provides the SCORM Data
     * lesson_location = contains the traditional ^^nnvvcc format and
     * sco =  contains the details tracking (topic / learning aid level ) informations on the course
     */
      const progressPath =
      PlayerConstants.API_SCO_DESTINATION + PlayerConstants.API_GET_AUTH;
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        };

    let proJson; // Progress json getting from Platform
    let trigger=0;
    const getAuth = async () => {
        try {
          const response = await fetch(progressPath, headers);
          const proJson = await response.json();
          trigger++;
    
          if (proJson.status === 'fault' && trigger < 3 && roleId === 4) {
            // recursive call with await
            await getAuth();
          }
          return proJson
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    
    if (!BuildHelper.isOnScorm()) {
          proJson=yield getAuth();
          /* 
            *'validate-session' - this call prevent the url sharing 
            * only while storefront only we checking it
            *
            */
          if(roleId === 4 && proJson?.status === 'success' && window.ce?.platform_scorm?.apiReferrer === 'storefront'){
            const path=PlayerConstants?.BETA_PATH||""
              const validationPath = `${path+"cache/validatesession"}`;
              let validatesession={
                uid:window.ce?.ceuser?.uid||"",
                auth_token:getAuthToken()||"",
                email:window.ce?.ceuser?.email||""
              }
              const sessionData = { method: "POST",headers: {"Content-Type": "application/json" },
                body: JSON.stringify(validatesession),
              };
          
              const validate = yield fetch(validationPath, sessionData).then( (response) => response.json());
              // if(validate?.status === 'fault')  yield put(utilityAction.authFault(validate));
          }
        //  if(roleId === 4 && proJson?.status === 'fault')  yield put(utilityAction.authFault(proJson));
    }
    const hasAuthSuccess= proJson?.status === "success"
    if (proJson?.data && hasAuthSuccess) {
      proJson.data.coursedata = convertToArray(proJson?.data?.coursedata);
    }
    if(hasAuthSuccess){
      // Initialize i18n inside the function
      proJson= yield DataHelper.getCourseProgress(proJson)
    }
    yield put(courseTrackingAction.responseProgress(proJson));
  let isInitializeI18n,scoValue, playerConfigJson; 

    if (hasAuthSuccess && proJson[0]) {
      // Parse the player configuration JSON if it exists
      const playerConfigJsonString = proJson[0]?.player_config_json;

      // Ensure playerConfigJsonString is a string before parsing
      if (typeof playerConfigJsonString === 'string') {
        playerConfigJson = JSON.parse(playerConfigJsonString)?.i18n || { languages: [] };
      } else {
        playerConfigJson =playerConfigJsonString?.i18n || { languages: [] };
      }

      // Set the language list in the SCORM platform configuration
      window.ce.platform_scorm.lnlist = playerConfigJson;

      // Check if i18n should be initialized
      isInitializeI18n = !playerConfigJson || !BuildHelper.isLearner() || (playerConfigJson.languages?.length || 0) < 2;

      // Parse the SCO value from the JSON if it's a string
      const scoJsonString = proJson[0]?.sco;
      if (typeof scoJsonString === 'string' && scoJsonString!=='') {
        scoValue = scoJsonString.length>0 ? JSON.parse(scoJsonString)||{} : {};
      } else {
        scoValue = scoJsonString;
      }
      // If there's a language specified in the SCO or i18n should be initialized, fetch the course
    }
    if (scoValue?.lan && playerConfigJson?.languages?.length > 0) {
      const lanId = playerConfigJson?.languages?.find(e => e?.lan === scoValue?.lan);
      if (lanId?.id) {
        localStorage.setItem(`${BuildHelper.configId()}_LN`, JSON.stringify(lanId?.lan));
        const fetchPayload={payload:{id:lanId.id}}
        yield fetchCourse(fetchPayload);
      }
    } else if (isInitializeI18n || !proJson || !proJson[0]) {
      yield fetchCourse();
    }
  }catch(error){
    console.error("Fetch course:", error);
  }
}

function* fetchCourse() {
  try {
    const data = arguments[0];

    const configId =data?.payload?.id ?? window?.ce?.rlm?.config_id ?? "6171396a1ff979024daafafc";
    const roleId = Number(window.ce?.ceuser?.role_id) || 4;
    const isUpdate = data?.payload?.isUpdate
    const path = "courseconfig/getconfigstructure";
    const hasCourse=DataHelper.isCourse();

    /*
     * window?.ce?.isScormPack === true // scorm package
     * depends on the isScormPack flag change the courstructure
     * will give you structure
     */

    let crs_endpoint;
    /**
     * TEST URL for CURATOR DATA FLOW
     * Archived Video API
     * Its configuration or course
     * Depends on that change endpoint
     */
    console.log(process?.env);
    console.log(arguments[0]);
    console.log("-----------Init SCORM------");

    // very first call to verify the publishing status
    if (roleId !== 4 && DataHelper.isPublish()) {
      let publishListList = yield fetch(`${getHost()}courseversion/getcourseversion/${configId}`).then(
        (response) => response.json()
      );
      if (publishListList?.status === "success" && publishListList?.data?.length > 0) {
        if (publishListList?.data[0]?.processed === 0 && window?.ce?.platform_scorm) {
          window.ce.platform_scorm.ispublished = false;
        }
        yield put(courseEditAction.publishVersion(publishListList?.data));
      }
    }

    if (hasCourse)crs_endpoint = `${getHost()}courses/getcoursestructurev2/${configId}`;
    else crs_endpoint = `${getHost()}courseconfig/getcourseconfigstructurev2/${configId}/${roleId}`;
      //Un-comment this for local data json and upload the data in coursedata.json present in public folder
      // crs_endpoint = "./coursedata.json";

    if (window.ce.isScormPack || window?.ce?.isStandalone) {
      let language=BuildHelper?.defaultLanguage() || 'en_US'
      crs_endpoint = `content/${language}/data/coursedata.json`;
    }

    const json = yield fetch(crs_endpoint).then((response) => response.json());

    // const json=  testStructure
    if (json?.data) {
      json.data.coursedata = convertToArray(json.data.coursedata);
    }

    /**
     * 
     */
    if(!hasCourse && !BuildHelper.isLearner()){
       let get_courseconfig_data=`${getHost()}courseconfig/getcomments/${configId}`
       let cf_data =  yield fetch(get_courseconfig_data).then((response) => response.json());
       if(cf_data?.status==="success" && cf_data?.data?.length > 0){
         json.data.CfgClientComm=cf_data?.data;
       }
    }



    yield put(courseInfoAction.responseCourseInfo(json));
    yield put(courseTrackingAction.initTracking({ ...json, update: isUpdate }));


    if (isUpdate) {
      let updatePayload = {
        course_id: window?.ce?.rlm?.config_id,
        newcrsid: configId,
        userid: window?.ce?.ceuser?.uid || '',
      }
      const updateData = { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(updatePayload) };
      yield fetch(PlayerConstants?.UPDATE_I18N_COURSE_CHANGE, updateData).then((response) => response.json());
    }

    /*
    **
    *  UI settings: We are using this data globally.
    *  Additionally, we are storing global fonts, spacing, colors, and more.
    */
    const globalSettings = { ...json?.data?.courseattributes?.ui || {}, info: (json?.data?.courseattributes?.info || {}) };
    // Pre-test is active. We are fetching the quiz questions for the active quiz.
    let quizresponse = [];
    if (globalSettings?.pre_test?.d?.items?.length > 0 && !isUpdate) {
      // yield getQuizQuestions();
      const postData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify(DataHelper.getArchiveIds(json?.data?.coursedata)),
      };
      let quizPath = `${getHost()}learning_aids/getquizbank`;
      try {
        const response = yield fetch(quizPath, postData);
        quizresponse = yield response.json();
        yield put(ceAssetsInfoAction.getQuizQuestionsReponse(quizresponse));
      } catch (error) {
        console.error('Error fetching quiz questions:', error);
        // Handle the error appropriately, e.g., dispatch an error action
      }
    }
    yield put(globalStyleAction.globalStyleResponse({ g: globalSettings, q: quizresponse }));



    /**
     * To list all the templates
     * It can go as a separate generator
     * fetch the below calls when ever the roleId is 1
     * Only on Editor it should call
     * */
    if (Number(roleId) !== 4 && !isUpdate) {
      let crsListPayload={
        method: "POST",
      }
      if(!hasCourse){
        crsListPayload={
          method: "POST",
          headers: {"Content-Type": "application/json",},
          body: JSON.stringify({ 
            clientid:window?.ce?.ceuser?.pvtid,
            configid:configId
          })
        }
      }
      const response = yield fetch(`${getHost()}courses/listtemplates`,crsListPayload).then(
        (response) => response.json()
      );
      let allTps = [];
      if (response.status === "success") {
        allTps = response.data;
      }
      yield put(courseEditAction.setTemplateList(allTps));

      /**
       * Fetching course list to search for the learning aids
      */
     if(hasCourse){
        let crsPath='courses/getCourses';
        // crsPath=`courses/getcontractedcourses/${String(window?.ce?.ceuser?.pvtid)}`
        const courseslist = yield fetch(`${getHost()}${crsPath}`).then(
          (response) => response.json()
        );
        let aciveCourse = [];
        if (courseslist.status === "success") {
          aciveCourse = courseslist.data;
        }
        yield put(courseEditAction.setCourseList(aciveCourse));
      }
    }
  } catch (e) { }
}


function convertToArray(data) {
  return Array.isArray(data) ? data : [data];
}

function* saveComment() {
  const data = arguments[0];
  let path = "learning_aids/addcomments";
  //this check and this changes are only for configuring non-customized learning elements
  if(data?.payload?.configid)path="courseconfig/addcomments"
  const cmmt_endpoint = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const commentResponse = yield fetch(cmmt_endpoint, postData).then(
    (response) => response.json()
  );
 if(commentResponse?.status==="success") yield put(courseInfoAction.saveCommentResponse(commentResponse));
}

function* deleteComment() {
  const data = arguments[0];
  let path = "learning_aids/deletecomments";
  if(data?.payload?.configid){ // this check and this changes are only for configuring non-customized learning elements
    path = "courseconfig/updatecomments";
 }
  const cmmt_endpoint = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const commentResponse = yield fetch(cmmt_endpoint, postData).then(
    (response) => response.json()
  );
 if(commentResponse?.status==="success") yield put(courseInfoAction.saveCommentResponse(commentResponse));
}

function* updateComment() {
  const data = arguments[0];
  let {path, ...payload}=data.payload;
  switch (path) {
    case 'p1':
      path = "learning_aids/updatecomments";
      break;
    case 'p2':// Update non-customized learningAid (lrn) comments for the configured course
      path = "courseconfig/updatecomments";
      break;
    case 'p3':
      path = "courseconfig/updateresolution";
      break;
    default:
      path = "learning_aids/updatecomments"; 
  }
  const cmmt_endpoint = `${getHost()}${path}`;
  // console.debug("data update",data)
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(payload),
  };
  const commentResponse = yield fetch(cmmt_endpoint, postData).then(
    (response) => response.json()
  );
 if(commentResponse?.status==="success") yield put(courseInfoAction.saveCommentResponse(commentResponse));
}

function* updateLearningAid() {
  const data = arguments[0];
  let path = "learning_aids/";
  let reqData = {};
  let cmmt_endpoint = "";
  let type= "PUT"
  if (data.type === types.UPDATE_STATUS_LEARNING_AID) {
    reqData = data.value.update;
    cmmt_endpoint = `${getHost()}${path}${data.value.update.id}`;
    // This check and this changes are only for configuring non-customized learning elements
    if(reqData?.type===2){
      cmmt_endpoint = `${getHost()}courseconfig/addcomments`;
      type='POST'
    }
  } else if (data.type === types.SAVE_LEARNING_AID) {
    reqData = { props: data.payload.data };
    cmmt_endpoint = `${getHost()}${path}${data.payload.id}`;
  } else {
    // do nothing
  }
  const postData = {
    method:type,
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(reqData),
  };
  const commentResponse = yield fetch(cmmt_endpoint, postData).then(
    (response) => response.json()
  );
  if(reqData?.type===2 && commentResponse?.status==="success" )yield put(courseInfoAction.saveCommentResponse(commentResponse));
}
function* updateLrnStatusInBulk() {
  const data = arguments[0]?.payload;
  let path = "courseconfig/updatelrnstatusinbulk";
  const learning_update_endpoint = `${getHost()}${path}`;
  let payload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data?.payload),
  };
  const bulkStatusResponse = yield fetch(learning_update_endpoint, payload).then(
    (response) => response.json()
  );

  if(bulkStatusResponse?.status==="success"){
     yield put(courseInfoAction.responseLrnStatus(data?.response));
  }

}

/** selected template will assigned here */
function* templateAssignment() {
  const data = arguments[0];
  let path = "courses/attachtemplate";
  const learning_endpoint = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const templateResponse = yield fetch(learning_endpoint, postData).then(
    (response) => response.json()
  );
  if (templateResponse) {
    yield put(utilityAction.applyTemplateToast(templateResponse));
  }
  yield put(courseInfoAction.setAssignedTemplate(templateResponse));
}

/**
 * check previously item is cloned or not then switch the api endpoint
 * only for the configuration
 */
function isLrnItemCloned(lrncontent) {
  let cloned = false;
  lrncontent.forEach((lrn) => {
    lrn.logs.forEach((log) => {
      if (log.status === -2) {
        cloned = true;
      }
    });
  });

  return cloned;
}

function* addNewLearningAid() {
  let data = arguments[0];

  // /** Configuration & Course using 2 different endpoints */
  let path = "courses/newlearnaid";
  if (!DataHelper.isCourse()) {
    data.payload["configid"] = window.ce.rlm.config_id;
    path = "courses/addlearningaid";
    // console.debug("data?.payload",data?.payload,isLrnItemCloned(data?.payload?.topic?.lrncontent));
    // if(!isLrnItemCloned(data?.payload?.topic?.lrncontent)){
    //     path = 'courses/addlearningaid';
    // }else{
    //     path = 'courses/newlearnaid';
    // }
    /** For request is not a mandatory so removing this topic */
    delete data.payload["topic"];
  } else if (DataHelper.isCourse()) {
    delete data.payload["topic"];
  }
  //removing markup inside any commas
    if(data?.payload?.markups && data?.payload?.markups?.includes(",")){
        data.payload["markups"]= data?.payload?.markups?.replace(/,/g,"");
    }

  const lrnaid_endpoint = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const newaidResponse = yield fetch(lrnaid_endpoint, postData).then(
    (response) => response.json()
  );
  if (newaidResponse) {
    yield put(utilityAction.addLearningAidToast(newaidResponse));
  }
  yield put(courseInfoAction.learningAidAdded(newaidResponse));
}

function * getPublishCourseLRNId(){
  const data = arguments[0];
  let newLrnPayload= DataHelper?.deepClone(data?.payload?.payload);
  let publish={...data.payload.publish??{}};
  let existingLrnCollectionAdd=data?.payload?.existingLrnCollectionAdd
  const path = `${getHost()}${'courseversion/addlearningaid'}`;
  if(existingLrnCollectionAdd){
    newLrnPayload.lrnItem.props.items=newLrnPayload?.lrnItem?.props?.items?.map((e)=>(e?._id||e))
  }
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    }, body: JSON.stringify(newLrnPayload),
  };
  const LrnResponse = yield fetch(path, postData).then((response) => response.json());
  //adding the lrn temp id inside the props
  if(LrnResponse?.status === "success"){
    let lrnId=LrnResponse?.data||"";
    let lrnItem =data?.payload?.payload?.lrnItem||{};
    publish.aidItem={...newLrnPayload?.lrnItem,_id:lrnId}
    let updatedProp={...lrnItem,_id:lrnId}
      if (updatedProp) {
        yield put(utilityAction.addLearningAidToast(updatedProp));
      }
      // updating the publish array 
      const {aidItem, courseEdit, courseInfo, trackInfo, updateLatestPublish} = publish;
      yield put(courseInfoAction.publishAddNewLRN({data:updatedProp, markup:newLrnPayload?.markups}));
      yield DataHelper.publishFormatting(aidItem, courseEdit, courseInfo, trackInfo, updateLatestPublish,{type:2,markup:newLrnPayload?.markups });
  }
}


function* deleteLearningAid() {
  let data = arguments[0];
  let path = "courses/deletelearningaid";
  //removing markup inside any commas
    if(data?.payload?.markup && data?.payload?.markup?.includes(",")){
        data.payload["markup"]= data?.payload?.markup?.replace(/,/g,"");
    } 
  const remove_learningaid = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const rmaidResponse = yield fetch(remove_learningaid, postData).then(
    (response) => response.json()
  );
  if (rmaidResponse){
     yield put(utilityAction.removeLearningAidToast(rmaidResponse));
  }
  yield put(courseInfoAction.learningAidRemoved(rmaidResponse));
}

function* saveAsTemplate() {
  const data = arguments[0];
  let path = "courses/saveastemplate";
  const saveas_template = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const response = yield fetch(saveas_template, postData).then((response) =>
    response.json()
  );
  if (response) {
    yield put(utilityAction.savedAsTemplateToast(response));
  }
  yield put(courseEditAction.templateAdded(response));
}

function* cloneLearningAid() {
  const data = arguments[0];
  let path = "courses/clonetopicwithlearningaid";
  const clone_learningaid = `${getHost()}${path}`;

  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const response = yield fetch(clone_learningaid, postData).then((response) =>
    response.json()
  );

  const message = {
    cloneId: data?.topicid,
    clone_newId: response?.data?.id,
  };

  if (response?.result === 1) {
    DataHelper.windowMessage(message);
  }

  if (response) {
    yield put(utilityAction.learningAidClonedToast(response));
  }
  yield put(courseInfoAction.learningAidCloned(response));
 if(response?.status==="success"){
 // let newLrn=response?.data?.topic.lrncontent.flitter(e.props.topicid)
   let responseData={
    ...data.payload,
    title:response?.data?.topic?.title,
    topicid:response?.data?.topic?.id,
   }
   yield put(courseEditAction.updatePersonalizeHistory(responseData))
 } 
}

function* getVideoAddonItems() {
  const data = arguments[0];
  const url =
    "//appproxy.contentenablers.com/curator/index.php/api/supplementaries/getCuePoints/id/" +
    data.payload;
  // const postData = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data.payload) }
  const cuePointsResponse = yield fetch(url).then((response) =>
    response.json()
  );
  if (cuePointsResponse) {
    console.log("cuePoints", cuePointsResponse);
    yield put(courseInfoAction.setVideoAddonItems(cuePointsResponse));
  }
}

/** updating topic title and duration */
function* editTopic() {
  const data = arguments[0];
  let path = "courses/updateTopic";
  const topic_edit = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const tpResponse = yield fetch(topic_edit, postData).then((response) =>
    response.json()
  );
  console.log("blalalall");
  if (tpResponse) {
    yield put(utilityAction.topicUpdatedToast(tpResponse));
  }
  yield put(courseInfoAction.updateTopic(tpResponse));
}

function getAuthToken() {
  return window.ce?.platform_scorm?.authtoken;
}
function getHost() {
  // const apihost = window.location.href.includes("localhost") ? 'https://tradecompliance.training/xapice/api/' : 'https://tradecompliance.training/xapice/api/'
  const apihost =
    PlayerConstants.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1
      ? "https://tradecompliance.training/xapice/api/"
      : "https://api-cea.contentenablers.com/api/";
  return apihost;
}

// function getAssets() {
//     const apihost =' https://contentenablers.dev/xapice/api/assets/'
//     return apihost;
// }

function* saveCourseProgress() {
  /**
   * ids are passed from window.ce
   * arguments[0] contains the whole tracking reducer state object
   */
  const params = arguments[0]?.payload;
  // const isScormInitialsed = params.scorm.intialised || false;

  let obj = {
    cid: window.ce?.platform_scorm?.cid,
    uid: window.ce?.ceuser?.uid,
    pid: window.ce?.ceuser?.pvtid,
    sco: JSON.stringify(arguments[0]?.payload?.courseTracking),
  };

  let path =
    PlayerConstants.API_SCO_DESTINATION + PlayerConstants.API_SAVE_LEARNING;
  let postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(obj),
  };

  /**
   * returning if the mode is scorm and running in local
   * TODO:  in local implement the track saving local server
   *
   * And if its scorm, saving it to suspend data
   */
  // if(BuildHelper.isOnScorm() || BuildHelper.isLocalRun())    {
  if (BuildHelper.isOnScorm()) {
    console.log("Saving to SCORM --- ", params);
    if (params?.scorm !== undefined) {
      params.courseTracking.lan=BuildHelper.defaultLanguage();
      let encrypted = params.scorm.encryptProgress(params.courseTracking);
      params.courseTracking.scorm = { sd: encrypted };
      params.scorm.setScormData(encrypted);
    }
    return;
  }

  try {
    const respons = yield fetch(path, postData).then((response) =>
      response.json()
    );
    if (respons) {
      yield put(courseTrackingAction.progressSaved(respons));
    }
  } catch (e) {
    console.warn("Saving API on LMS Platform not returned");
  }
}

/**
 * Search Learning aids
 */

function* searchLearningAids(searchQery) {
  let path = `${getHost()}learning_aids/searchlearningaids`;
  let payload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(searchQery.payload),
  };

  const lrnSearch = yield fetch(path, payload).then((response) =>
    response.json()
  );
  let searchResult = [];
  searchResult = lrnSearch?.data||[];
  yield put(courseEditAction.lrnAidsSearchResult(searchResult));
}

function* addExistingLearningAid() {
  const data = arguments[0];
  let path = "courses/addexistinglearningaid";
  const lrnaid_endpoint = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const response = yield fetch(lrnaid_endpoint, postData).then((response) =>
    response.json()
  );
  if (response) {
    yield put(utilityAction.addLearningAidToast(response));
  }
  yield put(courseInfoAction.learningAidAdded(response));
}

function* getAcl() {
  const data = arguments[0];
  let path = "learning_aids/getacl";
  const acl_endpoint = `${getHost()}${path}`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),
  };
  const aclresponse = yield fetch(acl_endpoint, postData).then((response) =>
    response.json()
  );
  yield put(userInfoAction.userAclResponse(aclresponse));
}

 /**
 * By default, the initial state uses `null`.
 * `data` is set to `null` only when making the API call; otherwise, it remains as is.
 * The API triggers for images, audio, and video are handled in the `UploaderPanel`.
 * The API trigger for GIFs is handled in the `AssetUploader.js`.
 * During configuration, we send the crsids data.
 * During the course time, the payload crsids is empty.
 * 
 */
function* getceAssets() {
  const data = arguments[0];
  const ce_assets_path =`${getHost()}assets/getassetsbyconfig`;
  const getData = {
     method: "POST",
     headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data.payload),

   };
  const ceresponse = yield fetch(ce_assets_path, getData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.ceAssetsResponse({...ceresponse,rtype:data?.payload?.type}));
}

function* getceAssetsWishlist() {
  const data = arguments[0];
  let path = `wishlist/${data?.payload?.id}`;
  const ce_wishlist_path = `${getHost()}assets/${path}`;
  const getData = { method: "GET" };
  const ceresponse = yield fetch(ce_wishlist_path, getData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.ceAssetsWishlistResponse(ceresponse));
}

function* updateceLinkedAssets() {
  const data = arguments[0]?.payload;
  const payload = {
    id: data?._id||data?.id,
    cids: data?.cids,
    topicids: data?.topicids,
    lrnids: data?.lrnids,
    clientid:data?.clientid,
  };
  const ce_UpdateAssets_path = `${getHost()}assets/updateAsset`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(payload),
  };
  const ceresponse = yield fetch(ce_UpdateAssets_path, postData).then(
    (response) => response.json()
  );
}

function* getsupplimentaries() {
  if (window?.ce?.isStandalone) {
    return;
  }
  const params = arguments[0];
  try {
    const configId = window.ce?.rlm.config_id; //  '6310c671fb60336dcd3f2174' window.ce?.rlm.config_id
    let path =
      window?.ce?.ceuser?.type === "config"
        ? `learning_aids/getconfigsupplimentaries/${configId}`
        : `learning_aids/getcoursesupplimentaries/${configId}`;
    const supplimentaries_path = `${getHost()}${path}`;

    const supplementPath =
      params.payload < 0
        ? (BuildHelper.getExternalPath() !== '' ? BuildHelper.getExternalPath()  : process.env.PUBLIC_URL) + `/content/${BuildHelper?.defaultLanguage()||"en_US"}/data/supplements.json`
        : supplimentaries_path;

    const getData = { method: "GET" };
    const respons = yield fetch(supplementPath || {}, getData).then(
      (response) => response.json()
    );
   if(respons?.status === "success")  yield put(courseInfoAction.responseSupplimentaries(respons));
  } catch (e) {
    console.warn(e);
  }
}

function* updateCourseInformation() {
  const data = arguments[0]?.payload;
  const { info, ...uiSettings } = data;
  let payload = {
    type: window?.ce?.ceuser?.type === "config" ? 1 : 0,
    cid: window.ce?.rlm?.config_id,
    uisettings:uiSettings||{},
    info:info||{}
  };
  const updatecourseinfo = `${getHost()}courses/updatecourseinfo`;

  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(payload),
  };
  const updateResponse = yield fetch(updatecourseinfo, postData).then(
    (response) => response.json()
  );
  /*
  **
  * if the update response status is 'success' and the data version is 30( updating version 30 )
  **
  */
  // if(updateResponse?.status==="success" && Number(data?.version) !==20){
  //   const versionData={id:window?.ce?.configId||"",version:data?.version}
  //   const postVersion = {
  //     method:"POST",
  //     body: JSON.stringify(versionData),
  //   };
  //   const versionResponse = yield fetch(PlayerConstants?.VERSION_APPROXY, postVersion).then(
  //     (response) => response.json()
  //   );
  // }
  if (updateResponse)
    yield put(utilityAction.globalStyleUpdatedToast(updateResponse));
}

function* getArchiveCourse() {
  let path = `assets/getArchiveCourses`;
  // const supplimentaries_path =`${getHost()}${path}`;
  const supplimentaries_path =
    PlayerConstants.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1
      ? `https://tradecompliance.training/xapice/api/${path}`
      : `https://api-cea.contentenablers.com/api/${path}`;
  const getData = { method: "GET" };
  const respons = yield fetch(supplimentaries_path, getData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.setArchiveCourseResponse(respons?.data));
}

function* getArchiveSearchCrs() {
  const data = arguments[0];
  const payload = data?.payload;
  let path = `assets/searchByTopicContent/${payload.data}`;
  if (payload.type === "File Name")
    path = `assets/searchByParam/fileName/${payload.data}`;
  else if (payload.type === "Topic Name")
    path = `assets/searchByParam/title/${payload.data}`;
  // const supplimentaries_path =`${getHost()}${path}`;
  // const supplimentaries_path =`https://tradecompliance.training/xapice/api/${path}`;
  const supplimentaries_path =
    PlayerConstants.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1
      ? `https://tradecompliance.training/xapice/api/${path}`
      : `https://api-cea.contentenablers.com/api/${path}`;
  const getData = { method: "GET" };
  const respons = yield fetch(supplimentaries_path, getData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.setArchiveSearchResponse(respons));
}

function* getSelectedTopicVideoCrs() {
  const data = arguments[0];
  const payload = data?.payload;
  let path = `assets/searchByCourseId/${payload}`;
  // const supplimentaries_path =`https://tradecompliance.training/xapice/api/${path}`;
  const supplimentaries_path =
    PlayerConstants.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1
      ? `https://tradecompliance.training/xapice/api/${path}`
      : `https://api-cea.contentenablers.com/api/${path}`;
  const getData = { method: "GET" };
  const respons = yield fetch(supplimentaries_path, getData).then((response) =>
    response.json()
  );
  yield put(
    ceAssetsInfoAction.setArchiveSearchCrcIdResponse({
      ...respons,
      id: payload,
    })
  );
}

// eslint-disable-next-line require-yield
function* saveCuepoints() {
  const data = arguments[0]?.payload;
  const add_Cuepoints_path = `${getHost()}learning_aids/addcuepoints`;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(data),
  };
  const cueresponse = yield fetch(add_Cuepoints_path, postData).then(
    (response) => response.json()
  );
}

function* getQuizQuestions() {
  const data = arguments[0]?.payload?.flatten;
  const postData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(DataHelper.getArchiveIds(data)),
  };
  let quizPath = `${getHost()}learning_aids/getquizbank`;
  const quizresponse = yield fetch(quizPath, postData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.getQuizQuestionsReponse(quizresponse));
}

function* getQuizAddQuestions() {
  let quizPath = `https://tradecompliance.training/xapice/api/learning_aids/getquizbytopic/623c630ef7faae5e4470e63c`;
  const getData = { method: "POST" };
  const respons = yield fetch(quizPath, getData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.getQuizAddQuestionsReponse(respons));
}

function* getQuizUpdateQuestions() {
  let quizPath = `https://tradecompliance.training/xapice/api/learning_aids/getquizbytopic/623c630ef7faae5e4470e63c`;
  const getData = { method: "POST" };
  const respons = yield fetch(quizPath, getData).then((response) =>
    response.json()
  );
  yield put(ceAssetsInfoAction.getQuizUpdateQuestionsReponse(respons));
}
//need to change the api end point and courseid after adding the interactivity course in live
function* getcoursestructure() {
  const data = arguments[0]?.payload;
  const CRC_STRUCTURE_PATH = `https://tradecompliance.training/xapice/api/courses/getcoursestructure/${data}`;
  const getData = { method: "GET" };
  const respons = yield fetch(CRC_STRUCTURE_PATH, getData).then((response) =>
    response.json()
  );
  yield put(courseEditAction.responseCrcStructure(respons));
}

function* completionCall() {
  try {
    let mailPath =
      PlayerConstants.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1
        ? PlayerConstants?.AML_PROCESS_EMAIL_STAG
        : PlayerConstants?.AML_PROCESS_EMAIL;
    let payload = {
      user_id: window?.ce?.platform_scorm?.userId || "",
      course_id: window?.ce?.platform_scorm?.cid || "",
    };

    const payloadData = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    const response = yield fetch(mailPath, payloadData);
  } catch (error) {
    console.log("completionCall error");
  }
}


function* quizEventNotification() {
  try {
    const data = arguments[0]?.payload;
    let endPoint= Number(data?.type) === 0 ? "sendQuizAttmptMail" : "quizAttemptsList";
    let mailPath =PlayerConstants?.API_SCO_DESTINATION + endPoint;
    const payloadData = {method: "POST",headers: { "Content-Type": "application/json" },body: JSON.stringify(data?.postData),};
    const response = yield fetch(mailPath, payloadData);
  } catch (error) {
    console.log("quizEventNotification error");
  }
}

// publish the data when clicking on "Publish course"
function* publishToCourse(){
  const payload = arguments[0]?.payload;
  let publishPath =  `${getHost()}${'courseversion/addcourseversion'}`;
  const payloadData = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload?.publish),
  };
  const respons = yield fetch(publishPath, payloadData).then((response) =>response.json());
  if(respons.status === "success"){
    // updating the ar data
    yield put(courseTrackingAction.publishRes(payload?.flatten));
    // updating the publish version list
    yield put(courseEditAction.publishVersion(payload?.versionList));
  }
}


function* quizBankRequest(quizData) {
  const requstData=quizData?.payload;
  let path = `${getHost()}learning_aids/updatequizquestion`;
  let payload = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    body: JSON.stringify(requstData),
  };
  const quizResponse = yield fetch(path, payload).then((response) =>
    response.json()
  );
}

function* getPersonalizeData() {
  let path = `${getHost()}courses/getpersonalisedlrninfo/${window?.ce?.rlm?.config_id}`;
  let payload = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    }
  };
  const cloneResponse = yield fetch(path, payload).then((response) =>
    response.json()
  );
  if(cloneResponse?.status==='success') yield put(courseEditAction.setPersonalizeHistory(cloneResponse?.data));
}


function* actionWatcher() {
  try{
  yield takeLatest(types.COURSE_INFO_REQ, fetchCourse);
  yield takeLatest(types.CRS_PROGRESS_REQ, fetchProgress);
  yield takeEvery(types.COURSE_TRACKING_SAVE, saveCourseProgress);
  yield takeLatest(types.ASSIGN_TEMPLATE, templateAssignment);
  yield takeLatest(types.UPDATE_STATUS_LEARNING_AID, updateLearningAid);
  yield takeLatest(types.SAVE_LEARNING_AID, updateLearningAid);
  yield takeLatest(types.ADD_NEW_LEARNING_AID_REQ, addNewLearningAid);
  yield takeLatest(types.PUBLISH_CRS_GET_LRN_ID, getPublishCourseLRNId);
  yield takeLatest(types.DELETE_LEARNING_AID_REQ, deleteLearningAid);
  yield takeLatest(types.SAVE_AS_TEMPLATE_REQ, saveAsTemplate);
  yield takeLatest(types.CLONE_LEARNING_AID_REQ, cloneLearningAid);
  yield takeLatest(types.GET_VIDEO_ADDON, getVideoAddonItems);
  yield takeLatest(types.EDIT_TOPIC, editTopic);
  yield takeLatest(types.SAVE_COMMENT_REQ, saveComment);
  yield takeLatest(types.DELETE_COMMENT_REQ, deleteComment);
  yield takeLatest(types.UPDATE_COMMENT_REQ, updateComment);
  yield takeLatest(types.SEARCH_LRN_AIDS, searchLearningAids);
  // yield takeLatest(types.EXISTING_LRN_AID_ADD_REQ, addExistingLearningAid)
  yield takeLatest(types.USER_ACL_REQ, getAcl);
  yield takeLatest(types.CE_ASSETS_LIBARARY_REQ, getceAssets);
  yield takeLatest(types.CE_ASSETS_WISHLISTS_REQ, getceAssetsWishlist);
  yield takeLatest(types.CE_ASSETS_LINKED_REQ, updateceLinkedAssets);
  yield takeLatest(types.SUPPLIMENTARIES_REQ, getsupplimentaries);
  yield takeLatest(types.GET_SEARCH_BY_CID_REQ, getSelectedTopicVideoCrs);
  yield takeLatest(types.GLOBAL_STYLE_REQ, updateCourseInformation);
  yield takeLatest(types.GET_ARCHIVE_COURSES_REQ, getArchiveCourse);
  yield takeLatest(types.GET_ARCHIVE_SEARCH_REQ, getArchiveSearchCrs);
  yield takeLatest(types.GET_QUIZ_QUESTIONS_REQUEST, getQuizQuestions);
  yield takeLatest(types.GET_QUIZ_ADD_QUESTIONS_REQUEST, getQuizAddQuestions);
  yield takeLatest( types.GET_QUIZ_UPDATE_QUESTIONS_REQUEST,getQuizUpdateQuestions);
  yield takeLatest(types.SAVE_VIDEO_CUEPOINTS, saveCuepoints);
  yield takeLatest(types.GET_CRS_STRUCTURE, getcoursestructure);
  yield takeLatest(types.COURSE_COMPLETION_NOTIFIED, completionCall);
  yield takeLatest(types.QUIZ_TRACKING_EMAIL_REQUEST, quizEventNotification);
  yield takeLatest(types.PUBLISH_CHANGES_TO_CRS, publishToCourse);
  yield takeLatest(types.ADD_QUIZ_QUESTION_REQ, quizBankRequest);
  yield takeLatest(types.GET_PERSONALIZE_HISTORY, getPersonalizeData);
  yield takeLatest(types.UPDATE_LRN_STATUS_IN_BULK, updateLrnStatusInBulk);
  }catch(e){
    console.error("actionWatcher-----------------",e);
  }
}


export default function* rootSaga() {
  yield all([actionWatcher()]);
}
