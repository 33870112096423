import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './propertypanel.scss'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import ComponentConstants from '../../../utils/ComponentConstants';
import * as ceAssetsInfoAction from '../../../redux/actions/ceAssetsInfoAction';
import * as globalStyleAction  from '../../../redux/actions/globalStyleAction'
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';
import BuildHelper from '../../../utils/BuildHelper';


/**
 * 
 * @param {*} param0 
 * @returns 
 */

/** 
 * Selected Component props will be pass as input to this property panel 
 * handler : event to pass the properties
 * */

const PropertyPanel = ({ data, handler }) => {
    const [compntProperty, setCompntProperty] = useState({})
    const [editInfo, setEditStatus] = useState({ status: true })
    const dispatch = useDispatch()
    const {courseInfo,courseEdit} = useSelector(state => state);
    const ceAssetsInfo = useSelector(state => state.ceAssetsInfo);
    const courseTracking = useSelector(state => state.courseTracking);
    const {globalStyle,userInfo} = useSelector(state => state);
    const lrn_Id = useSelector(state => state.courseEdit?.itemInfo?._id);
    // const [ saveFlag, setSaveFlag ] = useState(false)
    const itemInfo = useSelector(state => state.courseEdit?.itemInfo);
    const c_Id = window?.ce?.rlm?.config_id;
    const t_Id = courseInfo?.topic?.id

    /**
     * 
     * @param {*} val 
     * invoked from various component Panel
     * handleProperties props to the respecive loaded Panels
     * setting the value in compntProperty
     */
    const handleCompProperties = (val) => {
        setCompntProperty(val)
        if (hasUpdated(val, compntProperty)) {
            setEditStatus({ ...editInfo, status: false })
        }
        // setSaveFlag(!saveFlag)
    }
    useEffect(() => {
        handler({ type: ComponentConstants.WYSIWYG, data: compntProperty })
    }, [compntProperty]);

    //split the src to  get the Ceassets Id
    const assetsId = (src) => {
        try {
            let list = src.split('/');
            let nameList = list[list.length - 1].split('.');
            let FileName = -1;
            if (nameList?.length > 0) {
                FileName = nameList[0]
            }
            return FileName;
        } catch (e) {
            console.debug(e);
        }
    }
    let ceAssets = ceAssetsInfo?.allCeAssets;
    /**
     * updateAssetIds - Efficiently updates asset IDs (learning, topic, course) based on the specified operation type.
     * Optimized for minimal operations, with early exits if no changes are needed. Handles ADD and REMOVE actions for the asset.
     * 
     * @param {string} id - The ID of the asset to update.
     * @param {string} type - Operation type ('ADD' to add IDs, 'REMOVE' to remove IDs).
     */
    const updateAssetIds = async (id, type) => {
      try {
        // Fetch current asset details
        const currentAsset = ceAssets[id];
        if (!currentAsset) return;

        // initialize IDs from the current asset
        let assetCIds = {
            lrn: currentAsset?.lrnids || [],
            tp: currentAsset?.topicids || [],
            crs: currentAsset?.cids || [],
        };
        
        // get index positions of the item within the asset arrays
        let cIdxs = {
            lrn: assetCIds.lrn.indexOf(itemInfo?._id),
            tp: assetCIds.tp.indexOf(t_Id),
            crs: assetCIds.crs.indexOf(c_Id),
        };

        // track if any changes are made to the asset
        let hasChanges = false;

        // handle 'ADD' type
        if (type === 'ADD' && cIdxs.lrn === -1) {
            assetCIds.lrn.push(itemInfo?._id);
            if (cIdxs.tp === -1) assetCIds.tp.push(t_Id);
            if (cIdxs.crs === -1) assetCIds.crs.push(c_Id);
            hasChanges = true;
        }


         // handle 'REMOVE' type
         if (type === 'REMOVE' && cIdxs.lrn !== -1) {
            assetCIds.lrn.splice(cIdxs.lrn, 1); // Remove learning ID

            // check if the topic or course is still in use
            let topicflatten = courseInfo?.flatten || {};
            const topicData = topicflatten[courseInfo?.topic?.nodeId];
            if (topicData) {
                // check if the topic is used by any other learning content except the current item
                const isTopicUsed = topicData.lrncontent.some(lrn => lrn._id !== itemInfo?._id && assetCIds.lrn.includes(lrn._id));
                
                if (!isTopicUsed) {// if the topic is not used, remove its ID from assetCIds.tp
                    assetCIds.tp.splice(cIdxs.tp, 1); // remove topic ID

                    // check if any other topics in the course are still using this course ID
                    const isCourseUsed = courseTracking?.courseTracking?.topic?.some(tp => tp.topicId !== c_Id && assetCIds.tp.includes(tp.topicId));
                    if (!isCourseUsed) {
                        assetCIds.crs.splice(cIdxs.crs, 1); // remove course ID
                    }

                }
            }
            hasChanges = true;
        }


         // update the asset if changes are made
         if (hasChanges) {
            const updatedAssets = {
                ...currentAsset,
                lrnids: assetCIds.lrn,
                topicids: assetCIds.tp,
                cids: assetCIds.crs,
                clientid: userInfo?.user?.pvtid,
            };

            // single dispatch call to update the asset state
            dispatch(ceAssetsInfoAction.ceAssetsLinkedRequest(updatedAssets));
            dispatch(ceAssetsInfoAction.ceAssetsLinkedResponse(updatedAssets));
        }
        }catch (e) {
            console.debug('Error updating asset IDs:', e);
        }

    }

    /**
     * This function handles CE Library asset usage update actions for asset transitions.
     * It checks if the asset has changed (from previous to current), updates asset IDs, and saves component properties if required.
     * 
     * Workflow:
     * 1. extracts the base URLs of the previous and current assets using the helper function.
     * 2. checks if the base URLs belong to a known ASSET_HOST list and retrieves their asset IDs.
     * 3. calls `updateAssetIds` to remove the previous asset and add the current asset if valid.
     * 4. if there are asset updates and non-empty properties (`prop`), triggers a handler to save the component properties.
     */
    const updateAssets = async (previous, current, prop) => {
        try{
           // helper function to get the base url (protocol + hostname) from a full url
            function getBaseURL(url) {
              try {
                 const { protocol, hostname } = new URL(url);
                  return `${protocol}//${hostname}`;
                } catch {
                  // return the original url if it's invalid
                  return url;
                }
              }
             // get base urls for both previous and current assets
                const pre = getBaseURL(previous);
                const crt = getBaseURL(current) ?? "";

                // only proceed if previous and current asset urls are different
                if (previous !== current) {
                    let preAssetId = -1;
                    let crtAssetId = -1;
                    const paths = PlayerConstants.ASSET_HOST;

                    // check if the base urls match known asset host paths
                    if (paths.includes(pre)) preAssetId = assetsId(previous);
                    if (paths.includes(crt)) crtAssetId = assetsId(current);

                    // remove previous asset if valid
                    if (preAssetId !== -1) {
                        await updateAssetIds(preAssetId, 'REMOVE');
                    }

                    // add current asset if valid
                    if (crtAssetId !== -1) {
                        await updateAssetIds(crtAssetId, 'ADD');
                    }


                    if ((crtAssetId !== -1 || preAssetId !== -1) &&  JSON.stringify( prop ) !== '{}' ){
                        await handler({ type: ComponentConstants.SAVE_COMP_PROPS,data:prop, id: data._id });
                    } 
                }

                // reset status flag after update
                editInfo.status=false;
           }catch (e) {
            console.debug(e);
        }
    }

    const getFileName=(src)=>{
        try{
            let splitArr=src?.split('/');
            let file
            if(itemInfo?.name==="Video"){
                file=splitArr[splitArr?.length-1].split('.');
            }else{
                file=splitArr[splitArr?.length-2].split('.');
            }
            return file[0] 
        }catch(e){}
    }

    /**
     * 
     * check the video is customized
     * confg & customized --> lrnid
    // --- * config & not customized --> assetid 
     * module --> assetid 
     * stype 5 client
     * 
     * @param {*} props 
     */
    const updateCuePoint=(props)=>{
        try{
            let newcueList=props?.cuepoints.map((el)=>{
             if(el?.shown) delete el?.shown;
             return el
            })
            let cueList={
                // if its a config we need to send lrnId ---config & not customised --> assetid 
                refid : (BuildHelper.isConfig() && (!props?.isPersonalized)) ? (props?.lrnId ??'' ) : getFileName(props?.src),
                cid : window?.ce?.rlm?.config_id, 
                stype: BuildHelper.isConfig() ? 5 : 4,
                status:1,
                cue:newcueList
            }
            dispatch( courseEditAction.saveCuepoints(cueList));
        }catch(e){}
     }

    /*
     // Update the quiz configuration settings globally
    */
   const applyToGlobal=(name,value,lrn)=>{
    let lrnData=globalStyle?.lrnSettings||{}
    lrnData[lrn]= {...lrnData[lrn],[name]: value||false};
    dispatch(globalStyleAction.updateGlobalLrnSettings(lrnData || {}));
}



    const renderPropertyPanel = (comp) => {
        try {

            let Component = require( `./${ comp.name }Panel` ).default;
            comp.props[ 'id' ] = comp?._id
            comp.props['name'] = comp?.name
            // console.debug("comp.props",comp.props)
            let isAssets=PlayerConstants.ASSETS_USED_COM.indexOf(comp.props.cename);
            let newComProp=comp.props
            if(isAssets > -1){
                newComProp={...newComProp,handleCeAssets:updateAssets}
            }
            //Global LRN settings
            if(["Quizzes"].indexOf(comp.props.cename) > -1){
                newComProp={...newComProp,handleCeAssets:updateAssets,glob:globalStyle?.lrnSettings["Quizzes"],applyToGlobal:applyToGlobal}
            }
            return <Component {...newComProp} c_Id={c_Id} t_Id={t_Id} handleProperties={handleCompProperties} handleClose={resetProps}  />
        } catch (e) {
            console.debug(e.message)
        }
    }


    // handler for video submission (ArchiveVideo and Video)
const handleVideoSubmission = () => {
    if (courseEdit?.isAddVideoSupplimentaries?.isActive) {
        // set customized property and update cue points
        compntProperty["isPersonalized"] = DataHelper.hasCustomized(data);
        compntProperty["lrnId"] = data?._id || '0';
        updateCuePoint(compntProperty);
    } else {
        // save component properties without cue points
        handler({
            type: ComponentConstants.SAVE_COMP_PROPS,
            data: { ...compntProperty, cuepoints: [] },
            id: data._id
        });
    }
};


// handler for quiz submission (Quizzes)
const handleQuizSubmission = () => {
    let items=[...(compntProperty?.items||[])];
    items=items?.map(data=>(data?._id))?.filter(Boolean);
    // save component properties along with quiz IDs
    handler({
        type: ComponentConstants.SAVE_COMP_PROPS,
        data: { ...compntProperty, 
            items: [...items]
        },
        id: data._id
    });
};

// Default handler for other component submissions
const handleDefaultSubmission = () => {
    // Save component properties as-is
    handler({
        type: ComponentConstants.SAVE_COMP_PROPS,
        data: compntProperty,
        id: data._id
    });
};

    const handleSubmit = () => {
    try{
        
        if (JSON.stringify(compntProperty) === '{}') {
            delete data.props.handler
            delete data.props.track
            console.log(data, 'unchanged object is ')
            return ; 
        }

        // Object mapping item names to their respective handlers
        const handlers = {
            "ArchiveVideo": handleVideoSubmission,
            "Video": handleVideoSubmission,
            "Quizzes": handleQuizSubmission,
            "default": handleDefaultSubmission
        };

        // get the corresponding handler based on itemInfo?.name or use default
        const submitHandler = handlers[itemInfo?.name] || handlers["default"];
        submitHandler(); // Call the appropriate handler

        // close the drawer after handling the submission
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }));
    }catch(e){
        console.error('Error in handleSubmit:', e);
    }
    }
    const resetProps = () => {
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
    }
    const customStringify = (value) => {
        const cache = new Set();
        return JSON.stringify(value, (key, val) => {
          if (typeof val === 'function' || val === undefined) {
            return null; // Replace functions and undefined with null
          }
          if (typeof val === 'object' && val !== null) {
            if (cache.has(val)) {
              return; // Handle circular references
            }
            cache.add(val);
          }
          return val;
        });
      };
    const hasUpdated = (compProps, newProps) => {
        const keys = new Set([...Object.keys(compProps), ...Object.keys(newProps)]);
        for (const key of keys) {
          if (customStringify(compProps[key]) !== customStringify(newProps[key])) {
            return true;
          }
        }
        return false;
    }



    return (
        <div>
            <div className="prop-panel card">
                {renderPropertyPanel(data)}
            </div>
            <div className='property-action-info'>
                <Button className='cancel-btn' onClick={resetProps}>Cancel</Button>
                <Button className='submit-btn' disabled={editInfo?.status} onClick={handleSubmit}>Save </Button>
            </div>
        </div>
    )
}


PropertyPanel.propTypes = {
    data: PropTypes.object,
}
PropertyPanel.defaultProps = {
    data: { props: {} }
};

export default PropertyPanel