import i18n from 'i18next';

/**
 * custom translation function that retrieves translation for a given key.
 * 
 * @param {string} key - the translation key.
 * @returns {string} - translated text corresponding to the provided key.
 */
const t = (key) => {
  return i18n.t(key);
};

export default t;
