import BuildHelper from "./BuildHelper";
import { pathCreater } from "./MediaConfig";
import PlayerConstants from "./PlayerConstants";
import ContentTracking from "../core/progress/ContentTracking";
import CourseTracking from "../core/progress/CourseTracking";
import TopicTracking from "../core/progress/TopicTracking";
import SCORMWrapper from "../core/lrs/SCORMWrapper";

const DataHelper = {
  listIterator: (list, cnt, parent, topics, flat, CfgClientComm) => {
    list.forEach((item) => {
      cnt++;
      let dep = parent + "." + cnt;
      //BAD !!
      delete item?.level;
      delete item?.status;
      item.nodeId = dep?.substr(1);
      item.p = 10;
      item.topic = item.id;
      flat[item.nodeId] = item;
      let lrncontent=item?.lrncontent?.filter(Boolean)||[]

      /**
       * only courseInfoReducer
       * course is configured and the particular learning element is not customized.
       * In this case, every save of the learning status and comments is stored in a separate bucket, so we make a separate call.
       * During configuration, if there are any customized comments or status data, we retrieve them and push them back into our course structure.
       */
      if(lrncontent?.length > 0 && !DataHelper.isCourse()){
         item.lrncontent=DataHelper.customizedDataProcess(lrncontent,CfgClientComm)
      }

      /**
       * Added for curtor Archived Video flow
       * In response topic content will be empty so added
       */
      if (lrncontent === null && !item.children) {
        item.lrncontent = item?.lrncontent;
      }
      if (item.children) {
        item.open = true; // open accordion status
        DataHelper.listIterator(item?.children, 0, dep, topics, flat, CfgClientComm);
      }
    });
  },
/**
 *  course is configured and the particular learning element is not customized.
 * In this case, every save of the learning status and comments is stored in a separate bucket, so we make a separate call.
 * During configuration, if there are any customized comments or status data, we retrieve them and push them back into our course structure.
 */
  customizedDataProcess:(lrncontent, cfdata)=>{
    let lrnElements=[...lrncontent];
      lrnElements = lrnElements?.map(element => {
        const lrn = { ...element };
        if (DataHelper?.hasCustomized(lrn)) {
          lrn.status =1;
          lrn.logs = [];
          const cfLrnData = cfdata?.find(cf => cf.lrnid === lrn?._id);
          if (cfLrnData) {
            const comments=cfLrnData?.comments?.map(e=>{return {...e,comments:e?.content, status:e?.ctype}})
            lrn.status = cfLrnData?.status;
            lrn.logs = [...comments];
          }
        }
        return lrn;
      });
    return lrnElements
  },
  /*
This function checks the completion status of a hierarchical structure of topics and subtopics.
steps:
  1. If the provided 'node' is missing or undefined, it considers the entire structure as completed and returns true.
  2. It checks if all immediate child topics (level 1) are completed, i.e., their completion percentage is 100%.
  3. If any child topic is not completed, it returns false, indicating that not all children are completed.
  4. If all immediate child topics are completed, it proceeds to check nested topics (topics within topics).
  5. If there are nested topics, it recursively calls itself to check if all nested topics are completed.
  6. Finally, if there are no nested topics or all nested topics are also completed, it returns true, indicating that all children and nested topics are completed.

  Note: The completion status is determined by the 'p' property of each node, where 100 represents completion, and anything else represents incompletion.
 
 */
  checkIfAllChildrenCompleted(node) {
    try {
      if (!node) return true;

      const completed = node.children?.every((child) => {
        return Number(child.p) === 100;
      });

      if (!completed) return false;

      const nestedTopic = node.children?.filter((child) => child.children);
      if (nestedTopic) {
        return nestedTopic.every((nestedtopic) =>
          DataHelper.checkIfAllChildrenCompleted(nestedtopic)
        );
      }

      return true;
    } catch (e) {
      return false;
    }
  },
   getInitials(name) {
    try {
      // remove any leading or trailing whitespace
      name = name?.trim();
  
      // split the name into parts by space
      const parts = name?.split(' ');
  
      // extract the initials
      const firstInitial = parts[0]?.charAt(0)?.toUpperCase() || '';
      const secondInitial = parts[1]?.charAt(0)?.toUpperCase() || parts[0]?.charAt(1)?.toUpperCase()||'';
  
      // combine the initials, ensuring a maximum of two letters
      const initials = firstInitial + secondInitial;
  
      return initials;
    } catch (e) {
      console.error('Error extracting initials:', e);
      return '';
    }
  },
  getCourseProgress:async(json)=>{
    try{
    let course =json?.data?.courses?.filter((el) => {
      return Number(el?.course_Id) === Number(window?.ce?.platform_scorm?.cid);
    });
     return course;
  }catch(e){
    console.error("getCourseProgress issue")
    return []
  }
  },
  checkTopicCompletion: (tracking, topic) => {
    try {
      const completed = tracking.content.filter((item) => {
        return item.status === 1;
      });
      if (completed.length === tracking.content.length) {
        tracking.percent = topic.p = 100;
        tracking.status = topic.status = 1;
      }
    } catch (e) {
      console.warn("Issue found ompletion of learning aids :: ", e);
    }
  },
  initializeSCORM:(userInfoAction,courseTrackingAction,dispatch)=>{
  try{
    let sco = new SCORMWrapper({})
    window.API = sco.getAPI();
    if(sco){
      sco?.initialze();
      dispatch(courseTrackingAction?.intialiseScorm(sco))
      console.log( sco,"!!!!!!!!!!!!!!!!!!!window.API",window.API);
      sco.getLessonLocation();
      let sname = sco.getStudentName();
      window.ce.ceuser.firstname = sname || "Test User";
      dispatch(userInfoAction.storeUserInfo(window.ce));
      //sco.getSuspendData();
    }
  }catch(e){
    console.error("initializeSCORM",e)
  }
  },

  getNewLrn: (currentId, markup, newItem) => {
    try {
      const markuplist = markup.split("</section>");
      let cmpidList = [];
      let newMarkupList = [];
      markuplist.forEach((item) => {
        let compids = item.replace(/\D/g, "");
        if (item.length > 10) {
          cmpidList.push(Number(compids));
        }
      });
      cmpidList.sort((a, b) => {
        return a - b;
      });
      const ncmpId = Number(cmpidList[cmpidList.length - 1]) + 1;

      console.log(cmpidList);
      console.log(markuplist);
      markuplist.forEach((item) => {
        let compids = item.replace(/\D/g, "");
        if (item.length > 10) {
          newMarkupList.push(item + "</section>");
          if (Number(compids) === Number(currentId)) {
            newMarkupList.push(
              `<section  name='${newItem?.name}' compId='com_${ncmpId}'></section>`
            );
          }
        }
      });

      return { markups: newMarkupList.join(""), cmpid: ncmpId };
    } catch (e) {
      console.log(e);
    }
  },
  getTime: () => {
    return new Date().getTime();
  },

  isCourse: () => {
    return window?.ce?.ceuser?.type === "course" ? true : false;
  },

  isEditor: () => {
    return(Number(window?.ce?.ceuser?.role_id ?? 4) === 1)? true : false;
  },

  hasScormInitialised: () => {},

  getLearningAidId: (coursename, nodeId, compid, id) => {
    try {
      // let name = 'China.Exports controls'.substring(0,6);
      // return target?.element?.id
      let name = coursename?.substring(0, 6);
      let crsname = "";
      if (name.includes(".")) crsname = name.split(".").join("");
      else crsname = name.split(" ").join("");

      if (crsname.includes(" ")) crsname = crsname.split(" ").join("");

      if (crsname) crsname = crsname.toUpperCase();

      let lid;
      if (typeof compid === "string") {
        lid =
          crsname +
          "_" +
          nodeId.split(".").join("_") +
          "_" +
          compid?.split("_")[1];
      } else {
        lid = crsname + "_" + nodeId.split(".").join("_") + "_" + compid;
      }
      if (lid !== undefined && lid !== null) return lid;
      else return id;
    } catch (e) {
      console.log(e);
    }
  },

  getLastTopic: (flatten) => {
    let keys = Object.keys(flatten);
    return flatten[keys[keys.length - 1]];
  },

  isAbsolutePath: (path) => {
    if (path.includes("assets/images/ph")) {
      return false;
    }
    return [
      "contentenablers.com",
      "https://d1oukasrldq7xd.cloudfront.net",
      "https://dfd4tx2w69ubk.cloudfront.net",
    ]?.some((substring) => path?.includes(substring))
      ? true
      : false;
  },
  getAllLRNCourseTrack :(info,lrn)=>{
   try{
    let LRNTrack=  Object.entries(info).flatMap(([key, values]) => {
        return values?.lrncontent
            .filter(e => e?.name === "Quizzes" && e) // Filter out empty or falsey objects
            .map(e => e?.props?.track||null) // Map to the filtered lrncontent objects
            .filter(Boolean);
    });
    return LRNTrack
    }catch(e){}
  },
retakeProgressData :(flatten,lrn) => {
    try{
    // let progressFlattened = {};
    let statusList = [];
    var courseTracking = new CourseTracking();
    for (var i in flatten) {
      let tptracking = new TopicTracking(i, flatten[i]?.id);
      if (flatten[i].lrncontent) {
        // if (flatten[i].lrncontent) {
        // let comp = flatten[i].lrncontent;
        let learnaids = flatten[i].lrncontent;
        /** Getting Component PermanentId from
         * PlayerConstants.getComponentByName(comp[j].name).id
         * comp[j].id is a configured Id ==> Given Id while creating template string
         * ex.0:1
         * COMPONENTS_LIST[0]:flatten[i].lrncontent[1]
         */
        statusList = [];
        for (var k in learnaids) {
          let tid = PlayerConstants.getComponentByName(learnaids[k]?.name)?.id + ":" + learnaids[k]?.compid;
          let ctr = new ContentTracking(tid, 0, {});
          if(lrn && lrn?.some((ln)=>ln?.id===tid)){
            let track=lrn.find((e)=>e?.id===tid)
            ctr= new ContentTracking(tid, 0, {...ctr.state,r:track?.state?.r||0});
          }
          statusList.push(ctr);
          tptracking.content.push(ctr);
        }
        // for (var j in comp) {
          // let cid =PlayerConstants.getComponentByName(comp[j]?.name)?.id +":" +comp[j]?.id;
          // let ctr = new ContentTracking(cid, 0, {});
  
          // statusList.push(ctr);
          // tptracking.content.push(ctr);
        // }
        courseTracking.topic.push(tptracking);
      }
  
      // progressFlattened[i] = {
      //   i: flatten[i]?.id,
      //   cmp: statusList,
      //   s: -1,
      //   cp: 0,
      //   ts: Date.now(),
      // };
    }
    /**
     * changing the first node active
     */
    // let tpKeys = Object.keys(progressFlattened);
    // let firstChild = tpKeys.find((c) => {
    //   return progressFlattened[c].cmp.length > 0;
    // });
    // progressFlattened[firstChild].s = 0;
    // console.log(progressFlattened[firstChild],progressFlattened)
    return { t: courseTracking }; // f: progressFlattened,
  }catch(e){
    console.log("retakeProgressData in DataHelper");
  }
  },

  initialiseProgressData :(flatten) => {
    try{
    let progressFlattened = {};
    let statusList = [];
    var courseTracking = new CourseTracking();
    for (var i in flatten) {
      let tptracking = new TopicTracking(i, flatten[i]?.id);
      if (flatten[i].lrncontent) {
        // if (flatten[i].lrncontent) {
        let comp = flatten[i].lrncontent;
        let learnaids = flatten[i].lrncontent;
        /** Getting Component PermanentId from
         * PlayerConstants.getComponentByName(comp[j].name).id
         * comp[j].id is a configured Id ==> Given Id while creating template string
         * ex.0:1
         * COMPONENTS_LIST[0]:flatten[i].lrncontent[1]
         */
        statusList = [];
        for (var k in learnaids) {
          let cid = PlayerConstants.getComponentByName(learnaids[k]?.name)?.id + ":" + learnaids[k]?.compid;
          let ctr = new ContentTracking(cid, 0, {});
          statusList.push(ctr);
          tptracking.content.push(ctr);
        }
        for (var j in comp) {
          let cid =
            PlayerConstants.getComponentByName(comp[j]?.name)?.id +
            ":" +
            comp[j]?.id;
          let ctr = new ContentTracking(cid, 0, {});
  
          // statusList.push(ctr);
          // tptracking.content.push(ctr);
        }
        courseTracking.topic.push(tptracking);
      }
  
      progressFlattened[i] = {
        i: flatten[i]?.id,
        cmp: statusList,
        s: -1,
        cp: 0,
        ts: Date.now(),
      };
    }
    /**
     * changing the first node active
     */
    let tpKeys = Object.keys(progressFlattened);
    let firstChild = tpKeys.find((c) => {
      return progressFlattened[c].cmp.length > 0;
    });
    progressFlattened[firstChild].s = 0;
    // console.log(progressFlattened[firstChild],progressFlattened)
    return { f: progressFlattened, t: courseTracking };
  }catch(e){
    console.log("initialiseProgressData in DataHelper");
  }
  },
  

  getResourcePath: (type, path) => {
    let url = path;
    try {
      if (BuildHelper.isOnScorm()) {
        let bucketOff = "library.contentenablers.com";
        let mgr = url.indexOf("manager");
        let cea = url.indexOf("ceauthor");
        let ceassets = url.indexOf("assets.contentenablers.com");
        let scmPath = url;
        if (ceassets > -1) {
          return path;
        }
        if (mgr > -1 || cea > -1) {
          scmPath = url.substring(
            url.indexOf(bucketOff) + bucketOff.length + 1
          );
          scmPath =
          BuildHelper.getExternalPath() + "./content/en_US/" + scmPath.substring(scmPath.indexOf("/") + 1);
            console.log("scmPath",scmPath)
          return scmPath;
        }
      }
      if (
        url.includes("https://s3.amazonaws.com") ||
        url.includes("https://s3.us-east-1.amazonaws.com")
      ) {
        const replace = url?.split(".com") || [url];
        url = `${PlayerConstants?.S3ASSETSPATH + replace[replace?.length - 1]}`;
        return url;
      }
      if (DataHelper.isAbsolutePath(url)) {
        return url;
      } else if (url.includes("assets/images/ph")) {
        return url;
      } else {
        if (url.includes("assets.contentenablers.com")) {
          return url;
        }
        return pathCreater(type) + url;
      }
    } catch (e) { }
  },

  getFilePath: (path, type) => {
    const match = PlayerConstants.ASSET_HOST.find((element) => {
      if (path.includes(element)) {
        return true;
      }
    });
    return match ? path : pathCreater(type) + path;
  },

  getSupplementarieFilePath: (path) => {
    try {
      const match = path && path?.includes(`http`) ? true : false;
      return match ? path : pathCreater(2) + path;
    } catch { }
  },
   /**
 * Checks if the current topic is completed.
 * A topic is considered completed if:
 * 1. The topic's progress (p) is 100% or its status is 1.
 * 2. All learning content items (lrncontent) have a track status greater than 0.
 *
 * @param {Object} tp - The topic object containing topic progress, status, and content items.
 * @returns {boolean} - Returns true if the topic is completed, otherwise false.
 */
   isCurrentTopicCompleted: (tp) => {
    try {
      const topicProgress = Number(tp?.p);  
      const topicStatus = Number(tp?.status);
 
      if (topicProgress === 100 || topicStatus === 1) return true;
 
      // Check if lrncontent exists and is an array
      if (!Array.isArray(tp?.lrncontent)|| tp?.lrncontent?.length < 1) return true;
 
      // Filter out null or falsy values, then check if every item is completed
      return tp?.lrncontent
        ?.filter(Boolean)
        ?.every((e) => (e?.props?.track?.status) > 0);
 
    } catch (e) {
      console.error("error:isCurrentTopicCompleted", e);
      return false;  // ensure function returns false in case of any exception
    }
  },
  /** isAllTopicCompleted Except the Last one  */
  isAllTpCompletedExceptLast: (flatten) => {
    if(Number(flatten['1']?.status)===1||Number(flatten['1']?.p)===100) return true;
    let keys = Object.keys(flatten);
    // console.debug("keys",keys)
    let lastKey = keys[keys.length - 1];
    let isCompleted = true;
    for (let key in flatten) {
    // Any place false happens we break the loop
      if (!isCompleted) {
        return isCompleted;
      }
      if (!(Number(key) === 1 || Number(key) === 0) && flatten[key]?.lrncontent?.length > 0 && key !== lastKey && DataHelper.checkEmptyTopic(flatten[key]?.lrncontent))
        isCompleted = flatten[key]?.lrncontent?.every((e => Number(e?.props?.track?.status) === 1))
    }
    return isCompleted;
  },
  topicplayer: () => {
    return window?.ce?.platform_scorm?.topicplayer === true ? true : false;
  },

  isAllTpCompleted: (flatten) => {
    if(Number(flatten['1']?.status)===1||Number(flatten['1']?.p)===100) return true;
    let isAllCompleted = true;
    for (let key in flatten) {
      if (flatten[key].status !== 1) {
        isAllCompleted = false;
      }
    }
    return isAllCompleted;
  },
  getLessonStatus: (flatten) => {
    let suspend = "";
    for (let key in flatten) {
      if (flatten[key]?.status === undefined || flatten[key]?.status === -1) {
        suspend += "n";
      }
      if (flatten[key]?.status === 1) {
        suspend += "c";
      }
      if (flatten[key]?.status === 0) {
        suspend += "v";
      }
    }
    return suspend;
  },
  checkContentIssues(data) {
    const resultNodes = [];
    try {
      function traverse(node) {
        if (node.lrncontent && node.markup) {
          const lrncontentArray = node.lrncontent;
          const markupCompIdText = node.markup;
          const compIdSet = new Set();
          const compIdCount = (markupCompIdText.match(/compId/g) || []).length;
          const hasNull = lrncontentArray.some((content) => content === null);
          const hasDuplicateCompId = lrncontentArray.some((content) => {
            const compid = content && content.compid;
            if (compid && compIdSet.has(compid)) {
              return true; // Duplicate compid found
            } else {
              compIdSet.add(compid);
              return false;
            }
          });
          if (
            lrncontentArray.length !== compIdCount ||
            hasNull ||
            hasDuplicateCompId
          ) {
            resultNodes.push({
              node,
              issues: {
                countMismatch: lrncontentArray.length !== compIdCount,
                hasNull,
                hasDuplicateCompId,
              },
            });
          }
        }

        if (node.children && node.children.length > 0) {
          node.children.forEach(traverse); // Recursive call for each child
        }
      }

      //   function hasDuplicateCompIdInArray(lrncontentArray) {
      //     const compIdSet = new Set();

      //     for (const content of lrncontentArray) {
      //       if (content && content.compid) {
      //         if (compIdSet.has(content.compid)) {
      //           return true; // Duplicate compid found
      //         } else {
      //           compIdSet.add(content.compid);
      //         }
      //       }
      //     }

      //     return false;
      //   }
      traverse(data);
    } catch (e) {
      console.log("error in cData", e);
    }
    return resultNodes;
  },
  getVideoAspectRatio: (width) => {
    let wd = width / 16;
    // let vw = width - 100
    let vh = wd * 9 - 100;
    // return { width:vw, height:vh };
    return { height: vh };
  },
  checkEmptyTopic: (lrnContent) => {
    try {
      if (
        lrnContent.length === 1 &&
        (lrnContent[0]?.name === "TopicDescription" ||
          lrnContent[0]?.name === "TopicHeader") &&
        lrnContent[0]?.props["description"].indexOf("Lorem Ipsum") !== -1 &&
        window?.ce?.ceuser?.role_id === 4
      ) {
        return false;
      } else {
        return lrnContent?.length > 0 || false;
      }
    } catch (e) {
      console.log("error", e);
    }
  },
   getArchiveIds : (data) => {
    try{
      let postData = { ccids: [], cids: [window.ce?.rlm?.config_id], type:window?.ce?.ceuser?.type};//type:"config"
      const traverse = (nodes) => {
        nodes.forEach((node) => {
          if (node?.lrncontent) {
            node.lrncontent.forEach((el) => {
              if (el?.name === "ArchiveVideo" && el?.props?.info?.ccid) {
                 postData.ccids.push(String(el.props.info.ccid));
              }
            });
          }
          if (node?.children && Array.isArray(data)) {
            traverse(node.children);
          }
        });
        
      };
      const crsData=Array.isArray(data) ? data :Object.values(data);
      traverse(crsData);
      return postData;
    }catch(e){
      console.log('getArchiveIds error:',e);
    }
  },
  shuffle: (array) => {
    try {
      if (array?.length === 1) {
        return array; // No need to shuffle a single-element array
      }
      let shuffledArray = [...array];
      const arraysAreEqual = (arr1, arr2) =>
        arr1.length === arr2.length &&
        arr1.some((value, index) => value === arr2[index]);
      // keep shuffling the array until the shuffled version is different from the original
      while (arraysAreEqual(shuffledArray, array)) {
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
          ];
        }
      }

      return shuffledArray;
    } catch (e) {
      return [];
    }
  },
  MonthDayYearFormat: (date) => {
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // return new Date(date).toLocaleDateString(undefined, options) || new Date()
    const options = { year: "numeric", month: "long", day: "numeric" };
    try {
      const dateParts = date.split("-");
      const rearrangedDate =
        dateParts[1] + "-" + dateParts[0] + "-" + dateParts[2];
      return (
        new Date(rearrangedDate).toLocaleDateString("en-US", options) ||
        new Date().toLocaleDateString("en-US", options)
      );
    } catch (e) {
      console.log("error in date format");
      return new Date().toLocaleDateString("en-US", options);
    }
  },
  scrollCompletionDebouncing: (selector, callback, isLongContent) => {
    const isView = isLongContent || null;
    setTimeout(() => {
      let element = document.querySelector(selector);
      let position = element?.getBoundingClientRect();
      const isMobile =
        window.matchMedia("max-width: 768px").matches ||
        window.matchMedia("max-height: 450px").matches;
      // checking for partial visibility
      if (
        position?.top <= window.innerHeight &&
        position?.bottom >= 0 &&
        (isMobile || isView)
      ) {
        callback();
      } else {
        // checking whether fully visible
        if (position?.top >= 0 && position?.bottom <= window.innerHeight) {
          callback();
        }
      }
    }, 100);
  },
  timeConvert: (ms) => {
    try {
      const millis = Number(ms);
      let d = Number(millis);
      var s = Math.floor((d / 1000) % 60);
      var m = Math.floor((d / 1000 / 60) % 60);
      var h = Math.floor((d / 1000 / 60 / 60) % 24);
      var hDisplay = h > 0 ? String(h).padStart(2, "0") : "";
      var mDisplay = m > 0 ? String(m).padStart(2, "0") : "";
      var sDisplay = s > 0 ? String(s).padStart(2, "0") : "";

      if (hDisplay && mDisplay && sDisplay) {
        return hDisplay + ":" + mDisplay + ":" + sDisplay;
      }
      if (hDisplay && mDisplay) {
        return hDisplay + ":" + mDisplay + ": 00";
      }
      if (mDisplay && sDisplay) {
        return "00 :" + mDisplay + ":" + sDisplay;
      }
      if (sDisplay) {
        return "00 : 00 : " + sDisplay;
      }
      if (hDisplay) {
        return hDisplay + ": 00" + ": 00";
      }
      if (mDisplay) {
        return mDisplay + ": 00";
      }
    } catch (e) { }
  },
// function to calculate the global font size based on screen width
 getGlobalFontSize : (gf, sw = Number(window?.screen?.availWidth)) => {
  // Get the global font size from globalStyle 
  const globalFontSize = gf || 24;

  // calculate the font size based on various conditions
  let calculatedFontSize = globalFontSize;

  // Get the screen width
  const screenWidth = sw || Number(window?.screen?.availWidth);

  if (globalFontSize > 10) {
    if (globalFontSize <= 30) {
      // reduce font size for smaller screens
      calculatedFontSize = screenWidth <= 1200 ? globalFontSize - 6 : calculatedFontSize;
    } else if (globalFontSize < 20) {
      // reduce font size for smaller and medium screens
      calculatedFontSize = screenWidth <= 900 ? globalFontSize - 4 : calculatedFontSize;
    }

  }

  // Ensure the minimum font size is 12
  if (calculatedFontSize <= 10) {
    return 12;
  }

  return calculatedFontSize;
},
  windowMessage: (message) => {
    window.parent.opener && window.parent.opener.postMessage(message, "*");
    // const message = { type: "clone", clone: messag };
    window.parent.postMessage(message, "*");
  },
  deepClone :(obj, map = new WeakMap())=> {
  try{
    if (obj === null || typeof obj !== 'object') {
      return obj; // Return the object if it's not an object or is null
    }

    if (map.has(obj)) {
        return map.get(obj); // Return the cloned object if it's already cloned
    }

    let clone = Array.isArray(obj) ? [] : {}; // Create a new array or object

    // Store the cloned object in the map
    map.set(obj, clone);

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            clone[key] = DataHelper.deepClone(obj[key], map); // Recursively clone nested objects
        }
    }

    return clone; // Return the cloned object
  }catch(e){}
},
fetchTranslations : async () => {
  const jsonPath = PlayerConstants?.I18N_PATH
  try {
    const response = await fetch(jsonPath);
    if (!response.ok) {
      throw new Error(`Failed to fetch translations for language:en_US`);
    }
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error loading translation JSON:');
    throw error;
  }
},
lmsPostMessage:(crsTracking)=>{
    try{
      if (window.parent) {
        let ps = {ll :crsTracking?.scorm?.ll,ls:(Number(crsTracking?.scorm?.ls)||null),sp:crsTracking?.scorm?.sp,uid:window?.ce?.platform_scorm?.userId||"",cid:Number(window?.ce?.platform_scorm?.cid||1)  }
        window.parent.postMessage({ "platform_scorm": ps}, "*");
      }
    }
    catch(e){
      console.log(e)
    }
    if(Number(crsTracking?.scorm?.ls) === 0)
        crsTracking.scorm.ls = null;
},
  /*
  * quiz configuration functionally
  * resetCourse
  * handleRoute
  * lockCourse
  * 
  */
  resetCourse:(courseTracking,dispatch,topicInfo,courseTrackingAction,courseInfo)=>{
      try{
          if(courseTracking){
             let QuizLRN= DataHelper.getAllLRNCourseTrack(courseInfo?.flatten)
             let progressed = DataHelper.retakeProgressData(courseTracking.flatten,QuizLRN);
             courseTracking.courseTracking={...progressed?.t}
             topicInfo?.lrncontent?.forEach((e) => {
              const compidNumber = Number(e?.compid);
              const obj = QuizLRN?.find((q) => {
                  const qId = Number(q?.split(":")[0]); // Assuming QuizLRN contains IDs like "id:state"
                  return qId === compidNumber;
              });
          
              if (obj) {
                  e.props.track.state = { ...obj };
              }
          });
             dispatch(courseTrackingAction.saveCourseProgress(courseTracking));
          }
      }catch(e){}
  },
lockCourse:(dispatch,courseTracking,courseTrackingAction)=>{
    let progress={
       ...courseTracking,
    courseTracking:{
        ...(courseTracking?.courseTracking||{}),
        lock_course:true  
    },
    }
    dispatch(courseTrackingAction.saveCourseProgress(progress));
},
 sendQuizReport:(payload,courseTrackingAction,dispatch,userInfo)=>{
  let postData = { 
      id:payload?.tId||0,
      res:payload?.res||"",
      send_respmail:payload?.config?.validation?.fail?.rule?.send_respmail?.value,
      send_atmptmail_touser:payload?.config?.validation?.fail?.rule?.send_atmptmail_touser?.value,
      uid:userInfo?.uid||56797,
      cname:window?.ce?.rlm?.config_id||"4917"
   }
   if(payload?.type===1){
      postData={
          ids:payload?.config?.validation?.mail||""
      };
   }
   let type=payload?.type||0;   
   dispatch(courseTrackingAction.sendQuizAttemptMail({postData,type}));
},
// This function helps to scroll to the particular learning LRN.
 scrollToLrnElement : (element) => {
  const checkElement = () => {
    const sectionElement = document.querySelector(`[compid="com_${element?.compid}"]`);
    const container = document.getElementById('layout-scroll');
    if (sectionElement && container) {
      const containerTop = container.getBoundingClientRect().top;
      const sectionTop = sectionElement.getBoundingClientRect().top;
      const sectionPosition = sectionTop - containerTop + container.scrollTop-100;//-100 adjustment 
      if (container.scrollHeight > container.clientHeight) {
          container.scrollTo({
          top: sectionPosition,
          behavior: 'smooth',
          });
       }
    } else {
      setTimeout(checkElement, 100); // Check again after 100ms
    }
  };
  checkElement();
},
/**
 * 
 * SHORTCUT  key press to complete the course
 * @param {*} flatten 
 * @returns 
 */
 shortcutCompletion:(flatten)=> {
  let statusList = [];
  if (flatten) {
    var courseTracking = new CourseTracking();
    for (var i in flatten) {

      flatten[i].p = 100
      flatten[i].status = 1

      let tptracking = new TopicTracking(i, flatten[i]?.id);
      if (flatten[i].lrncontent) {
        let comp = flatten[i].lrncontent;

        statusList = [];
        for (var j in comp) {
          let cid =
            PlayerConstants.getComponentByName(comp[j]?.name)?.id +
            ":" +
            comp[j]?.id||comp[j]?.compid;
          let ctr = new ContentTracking(cid, 1, {});

          statusList.push(ctr);

          tptracking.percent = 100
          tptracking.status = 1

          tptracking.content.push(ctr);
        }
        courseTracking.topic.push(tptracking);
      }

    }
    // console.debug( "courseTracking", courseTracking )
  }
  return { t: courseTracking }
},

/**
 * 
 * @param {*} logs 
 * @returns 
 * We have logs [-1, -2,-3] in the LRN, which indicate customized
 * -1: Customized LRN (applied during cloning)
 * -2: New LRN (created after cloning)
 * -3: LRN after applying the template
 * 1: newly added one(using + add)
 * [-1,-2,-3,7]- resolved (7 we have edit option)
 * 1- active
 * 6- ToDo
 */
hasCustomized:(lrn)=>{
  try{
    if(!DataHelper.isCourse()){
      if(lrn?.logs && lrn.logs.length > 0){
        const isCustomized = lrn?.logs?.some((log) => log?.status === -1 || log?.status === -2 || log?.status === -3);
        if(isCustomized) return false;
      }
      return true
    }
    return false
  }catch(e){}
},

/**
 * creates a reusable auto-scrolling function for a container 
 * when the mouse is near the viewport edges. 
 * can be started and stopped as needed.
 *
 * @param {string} containerSelector - css selector for the container 
 *   to scroll (default is '#layout-scroll').
 * @returns {Object} object with startScrolling and stopScrolling 
 *   functions to control auto-scrolling.
 */
 createAutoScroll : (containerSelector = '#layout-scroll',tost = () => {}, threshold = 180, maxScrollAmount = 20) => {
  let scrollAnimationId; // to manage the scroll animation
  let hasShownUpToast = false; // flag for "Scroll up" toast
  let hasShownDownToast = false; // flag for "Scroll down" toast
 try{
  /**
   * automatically scrolls the container based on mouse position.
   * scrolling direction and amount depend on how close 
   * the mouse is to the viewport edges.
   *
   */
  const autoScroll = (e) => {
      const container = document.querySelector(containerSelector); 
      const clientY = window?.event?.clientY; // get vertical mouse position

      // calculate distance from the viewport edges
      const distanceFromTop = Math.max(0, threshold - clientY);
      const distanceFromBottom = Math.max(0, threshold - (window.innerHeight - clientY));
      
      let scrollAmount = 0; // amount to scroll

      if (clientY < threshold) {
          if (!hasShownUpToast) {
            tost("Scroll up");
            hasShownUpToast = true; // Show toast only once for scrolling up
          }
          // scroll up if near the top edge
          scrollAmount = Math.min(maxScrollAmount, distanceFromTop); // limit to max scroll amount
          container.scrollBy({ top: -scrollAmount, behavior: 'smooth' });
         
      } else if (clientY > window.innerHeight - threshold) {
          if (!hasShownDownToast) {
            tost("Scroll down");
            hasShownDownToast = true; // Show toast only once for scrolling down
          }
          scrollAmount = Math.min(maxScrollAmount, distanceFromBottom); 
          container.scrollBy({ top: scrollAmount, behavior: 'smooth' });
      }
      // continue scrolling with a short delay
      scrollAnimationId = requestAnimationFrame(() => autoScroll(e));
  };

  /**
   * starts the auto-scrolling by adding the mousemove event listener.
   */
  const startScrolling = () => {
    window.addEventListener('mousemove', autoScroll); // add listener for mouse move
    hasShownUpToast = false; // flag for "Scroll up" toast
    hasShownDownToast = false;
  };

  /**
   * stops the auto-scrolling by removing the mousemove event listener.
   */
  const stopScrolling = () => {
      window.removeEventListener('mousemove', autoScroll); // remove listener
      if (scrollAnimationId) {
          cancelAnimationFrame(scrollAnimationId);
      }
  };

  // return an object with start and stop functions to control the auto-scroll
  return { startScrolling, stopScrolling };
}catch(e){}
},
setExternalPath: async () => {
  try {
    const modulePath = `${process.env.PUBLIC_URL}/data/index.js`;
    const response = await fetch(modulePath);

    if (!response.ok) {
        throw new Error('Failed to fetch externalPath');
    }

    // Use text() to get the raw content of index.js
    const text = await response.text();

    // Extract the externalPath value from the raw content (simple example)
    // In a real scenario, you might need more sophisticated parsing
    const externalPath = text.match(/externalPath:\s*'(.*)'/)[1];
    window.sessionStorage.setItem('external-path', BuildHelper.isOnScorm() ? externalPath : '')
    // return externalPath;
} catch (error) {
    console.error('Error fetching externalPath:', error);
    window.sessionStorage.setItem('external-path', '')
    // return '';
}
},

/*
* publish latest array (new changes) formatting
*
* {  topic id : lrncontent: { lrn id : { lt:{latest props}, ar:{Archived prop}, id:"comp id" } }, markup :[0:old markup, 1: newMarkup], type: 1 } )
*
* custom?.type --- 1)update, 2)add, 3)delete, 4)template change (Applying a template) 5) updating topic lrn oder change
*
* Archived or 'ar' refers to the old course data before updating. When adding new data,
* we keep it as 'lrncontent:{lrn id: {}}', otherwise, we use the existing data
*
*/
// publish check
isPublish:()=>{
  return Boolean(window?.ce?.platform_scorm?.isconfigured);
},
publishFormatting : async(updatedLRN, courseEdit, courseInfo, trackInfo, dispatch, custom) => {
  try {
    // IDs are not present in any location, so we follow our standard to create a new  Comp_id.
    function getComp(name,id){
      return PlayerConstants.getComponentByName(name)?.id +":" +id;
    }
    let { publish, itemInfo} = courseEdit;
    let {flatten}=trackInfo;
    const itemData= custom?.itemInfo || itemInfo;

    let latest = { ...(publish??{})};

    const { topic, nodeId, markup } = courseInfo?.topic || {};
    let newData = updatedLRN||{};
    const type=custom?.type || 1;
    let topicId=`${topic}`;

    // Using the topic ID as the key
    const updateTopic = latest[topicId] || {};
    const activeARTopic = flatten[nodeId]||{} //finding the ar topic
    let arLRN = activeARTopic?.lrncontent?.find(e =>
       e && e?._id === (itemData?._id || (newData && newData?._id) || "")); // Find the lrn in the topic
       let lenName=(arLRN?.name || newData?.name||itemData?.name)
    // if(lenName==="Quizzes"){
    //   let items=arLRN?.props?.items ?? arLRN?.props?.items?.map((e)=>e?._id)
    //   arLRN.props.items=items;
    // }
    // markup [0:old, 1:new]
    const oldMarkup = activeARTopic?.markup;
    const newMarkup = custom?.markup ?? markup ?? updateTopic?.markup;
    let trackId={};
    let compId=newData?.compid||itemData?.compid||arLRN?.compid||""
    trackId= (itemData?.props?.track?.id || newData?.track?.id || newData?.props?.track?.id|| arLRN?.props?.track?.id ||getComp(lenName,compId)||""); 
    let updatedMarkup=[oldMarkup,newMarkup];
    
    // Main logic based on the custom type 
    switch (type) {
        case 2:  //  type 2 or 1: Add or update lrncontent props
        case 1:
         if(!newData?.props){ //update time  
            let compData=arLRN ?? latest[topicId][itemInfo?._id];
            newData={...compData,props:{...compData?.props,...newData}};    
          }
          latest[topicId] = {
            lrncontent: {
              ...updateTopic?.lrncontent,
              [newData?._id || itemData?._id||""]:{
                   lt:{ ...(newData ?? {}),cname:lenName||""},
                  ar:arLRN ? {...(arLRN ?? {}),cname:lenName} : null,
                   id:trackId || "" 
              } 
            },
            markup:updatedMarkup
          }

          break;
          case 3:
            // Check if the topicId exists in latest and latest[topicId].ar is not null
            if (latest[topicId] && latest[topicId]?.lrncontent[itemData?._id||newData?._id]?.ar === null) {
              delete latest[topicId].lrncontent[itemData?._id||newData?._id];
              if(Object.keys(latest[topicId]?.lrncontent)?.length > 0){
                latest[topicId]={
                  ...updateTopic,
                  markup:updatedMarkup
                }
              } else delete latest[topicId];
              break;
            }
             // type 3: Delete lrncontent prop and placing null
            latest[topicId] = {
              lrncontent: {
                ...updateTopic?.lrncontent,
                [newData?._id||itemData?._id||""]:{
                  lt:null,
                  ar:{...(arLRN ?? {}),cname:lenName},
                  id:trackId || ""
                }
              },
              markup:updatedMarkup
            }
            break;
            case 5:// update the topic markup
            latest[topicId] = {
              ...updateTopic||{},
               markup:updatedMarkup
            }
            // case 4: // type 4: Add new lrncontent's from template data
            //   const newLRN={};
            //   custom.data.forEach((lrn)=>{
            //     let arTempLRN = activeARTopic?.lrncontent?.find(e => e?._id === (itemInfo?._id||newData?._id||""));
            //     newLRN[lrn._id]={
            //      lt:{...lrn,cname:itemInfo?.name},
            //      ar:arTempLRN?.props||{}
            //     }
            //   })
            //   latest[topicId] = {
            //     markup:[oldMarkup,(custom?.markup ?? updateTopic?.markup ?? markup)],
            //     lrncontent: {...newLRN},
            //   };
            //  break;
             default:
              break;
    }
    dispatch(latest);
  } catch (error) {
    console.error('Error in publishFormatting:', error);
  }
}
};

export default DataHelper;
