import BuildHelper from '../../utils/BuildHelper';
import PlayerConstants from '../../utils/PlayerConstants';
import * as types from '../reducers/types';

const initialState = {
    comspace:{mt:3,mb:3,pl:0,pr:0,gfs:24,lh:1.5,isfs:false},
    font:'RobotoRegular',
    topic:{fs:48,color:'#000'},
    title:null,
    headers:{
     u:true,//Updates
     v:true,//Vignettes
     n:true,//CE Notes
     s:true,//Search
     c:true,//Certificate
     m:true//Course "Map
    },
    attest:{
     is:false,
     t:false,
     end:true
    },
    poweredby:PlayerConstants.POWERED_BY_CE,
    isLinear:false,
    isInstruction:false,
    homeImage:null,
    theme:"ce_theme",//skill_theme ce_theme
    lrnSettings:{
        Quizzes:{
            min:80,
            strict:false,
            iscfg:false,
            config:{validation:{},config:{} }
          }
    },
    pre_test:{is:false,d:[],t:null},
    ff_r:true,
    isCrsCompleted:false,
    isLoading:false,
    info:{},//Social media share and certificate 
    snc:false //StrictNavControl
}

const globalStyleReducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case types.GLOBAL_STYLE_RES:
            let uiData=action?.payload?.g||{};
            // const key= BuildHelper.isConfig() ? "cnf_q" : "crs_q";
            const quizBank=action?.payload?.q?.data ? action?.payload?.q?.data:[];
                window.ce.theme=uiData?.theme||state?.theme
                uiData={
                    ...uiData,
                    font:uiData?.font==="default" ? initialState?.font : (uiData?.font|| initialState?.font),
                    ff_r:uiData?.ff_r??initialState?.ff_r
                }
            if(quizBank?.length > 0){
              const quizMap= new Map(quizBank?.map(quiz => [quiz?._id, quiz]))
              const  quizData= uiData?.pre_test?.d?.items?.map(e => quizMap?.get(e)) || [];
              uiData={
                ...uiData,
                pre_test: { ...uiData?.pre_test, d:{...uiData?.pre_test?.d, items:quizData} }
              }
            }
            return {
                ...state,
                ...uiData,
                isLoading:false,
            }
        case types.UPDATE_GLOBAL_STYLE:
            let data=action?.payload||{}
         return {
                ...state,
                ...data,
            }
        case types.GLOBAL_COMSPACE_STYLE:
            return {
                ...state,
                comspace:action?.payload
            }
        case types.GLOBAL_FONT:
            return {
                ...state,
                font:action?.payload
            }
        case types.IS_LINEAR:
            return {
                ...state,
                isLinear:action?.payload
            }
         case types.GLOBAL_TOPIC_STYLE:
                return {
                    ...state,
                    topic:action?.payload,
                }
        case types.IS_HIDE_INSTRUCTION:
            return {
                ...state,
                isInstruction:action?.payload,
            }
        case types.HOME_PAGE_IMAGE:
            return {
                ...state,
                homeImage:action?.payload,
            }
        case types.GLOBAL_LRN_SETTINGS:
            return {
                ...state,
                lrnSettings:action?.payload,
            }
        default:
            return state
    }
}

export default globalStyleReducer